<template>
  <div>
    <div>
      <div class="row my-4 mx-1 justify-content-center">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <div v-else class="col-lg-8 col-sm-12 col-md-10 borderPointier">
          <div class="background-white">
            <div class="title">
              <div class="position">{{ $t("conditions_technique") }}</div>
            </div>
          </div>
          <form @submit.prevent="systeme_ventilationAdd">
            <div class="center mt-3 p-3">
              <div>
                <b-form-radio
                  v-model="selected"
                  class="mb-3"
                  name="radios1"
                  value="A"
                >
                  {{$t("Installation_Collective")}}
                </b-form-radio>

                <div class="mx-3" v-if="selected === 'A'">
                  <div>
                    <!-- Choix principaux (C ou D) -->
                    <b-form-radio-group v-model="selectedNorme" name="radiosNorme" class="mb-3">
                      <b-form-radio value="C">
                        {{$t("VMC simple flux hygroréglable")}}
                      </b-form-radio>
                      <b-form-radio value="D">
                        {{$t("VMBP (ventilation mécanique basse pression) simple flux hygroréglable")}}
                      </b-form-radio>
                    </b-form-radio-group>
                  
                  </div>
                  
                  <div>
                    <div v-if="selected=='A'">
                      <div  class="fieldset mx-3 mb-2" >
                        <div class="legend mb-2">
                          {{ $t("nombre_logements") }}
                        </div>
                        <b-form-input
                        type="number" 
                         @paste="(event) => onPaste(event, 'systeme_ventilationData.nombre_logements')"
                        @keydown="(event) => onKeyDown(event)"
                       min="0"
                          v-model="systeme_ventilationData.nombre_logements"
                          
                          :placeholder="$t('nombre_logements')"
                          class="form-input custom-placeholder custom-input"
                        >
                        </b-form-input>
                      </div>
                      <div class="fieldset mx-3 mb-2" >
                        <div class="legend mb-2">
                          {{ $t("Puissance") }}
                        </div>
                        <b-form-input
                        type="number"
                        min="0"
                        @keydown="(event) => onKeyDownFloat(event)"
                        v-model="systeme_ventilationData.puissance_electrique"
                        @paste="(event) => onPaste(event, 'systeme_ventilationData.puissance_electrique')"
                        :placeholder="$t('Puissance')"
                        class="form-input custom-placeholder custom-input"
                        
                      >
                      </b-form-input>
                      
                      </div>
                      
                    <!-- Choix additionnels (E ou F) -->
                    <b-form-radio-group v-model="selectedType" name="radiosType" class="mb-3">
                      <b-form-radio value="E">
                        {{$t("TYPE")}} A
                        <!-- {{$t("Les bouches d’extraction sont hygroréglables")}} -->
                      </b-form-radio>
                      <b-form-radio value="F">
                        {{$t("TYPE")}} B
                        <!-- {{$t("Les bouches d’extraction et les entrées d’air sont hygroréglables")}} -->
                      </b-form-radio>
                    </b-form-radio-group>
                    </div>
                    
                    
                
                  </div>
                  <div >
                  </div>
                </div>
                <b-form-radio
                  v-model="selected"
                
                  name="radios1"
                  value="B"
                >
                  {{$t("Installation individuelle")}}
                </b-form-radio>
                <div v-if="selected=='B'" class="fieldset mx-3 mb-2" >
                  <div class="legend mb-2">
                    {{ $t("surface") }}
                  </div>
                  <b-form-input
                  type="number" 
                  @keydown="(event) => preventExceedingLengthFloat(event)"
                 min="0"
                  @paste="(event) => onPaste(event, 'systeme_ventilationData.surface_habitable')"
                    v-model="systeme_ventilationData.surface_habitable"
                    :placeholder="$t('surface')"
                    class="form-input custom-placeholder custom-input"
                  >
                  </b-form-input>
                </div>
                <div v-if="selected=='B'"  class="fieldset mx-3 mb-2" >
                  <div class="legend mb-2">
                    {{ $t("classe") }}
                  </div>
                  <b-form-input
                    type="text"
                    v-model="systeme_ventilationData.classe_energetique"
                    :placeholder="$t('classe')"
                    class="form-input custom-placeholder custom-input"
                  >
                  </b-form-input>
                </div>
                <div class="fieldset mx-3 mb-2" v-if="selected==='B'">
                  <div class="legend mb-2">
                    {{ $t("Puissance") }}
                  </div>
                  <b-form-input
                  type="text"
                   @paste="(event) => onPaste(event, 'systeme_ventilationData.puissance_electrique')"
                @keydown="(event) => onKeyDownFloat(event)"
                  v-model="systeme_ventilationData.puissance_electrique"
                  :placeholder="$t('Puissance')"
                  class="form-input custom-placeholder custom-input"
                  @input="validateInput"
                >
                </b-form-input>
                
                </div>
            
              <div class="mx-2" v-if="selected==='B'">
                <b-form-radio
                v-model="selectedType"
                class="mb-3"
                name="radios2"
                value="E"
              >
              
                {{$t("TYPE")}} A
              </b-form-radio>
              
              <b-form-radio
                v-model="selectedType"
                class="mb-3"
                name="radios2"
                value="F"
              >
               {{$t("TYPE ")}} B
              </b-form-radio>
              </div>
              </div>
           
            </div>
         
          
          </form>
          <div v-if="show" class="d-flex justify-content-end mt-4">
      <b-button
        type="submit"
        @click="goToStep"
        class="button-suivant-style m-3"
      >
        <template v-if="isLoading">
          <div class="loading ml-2">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        </template>
        
        <template>
          {{ $t("CONFIRMER") }}
        </template>
      </b-button>
    </div>
        </div>
      </div>
     
    </div>
  
    <div v-if="!show"  class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{$t("PREVIOUS")}}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
      {{$t("NEXT")}}
      <div class="loading ml-2" v-if="isLoading">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <Snackbar ref="snackbar" />
  </div>
</template>

<script>
import { mapActions, mapGetters,mapState } from "vuex";
import domain from "@/environment";
import axios from "axios";
import Snackbar from '@/components/ui/errorSnackbar.vue';
import { handlePaste, preventExceedingLengthFloat, preventExceedingLength } from '@/utils/inputUtils'; 
export default {
  components: {
    Snackbar,
  },
  props: {

    show: {
      type: Boolean,
      default:false,
      required: false,
    },
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCompleted: false,
      errorMessage:'',
      isLoading:false,
      selected: "A",
      selectedNorme: "C",
      selectedType:"E",
      systeme_ventilationData: null,
      systeme_ventilation: false,
    };
  },
  created() {
    if (this.getdevisMonoFiche && this.getdevisMonoFiche.data) {
      this.systeme_ventilationData = this.initializesysteme_ventilation();
      this.selectedType = this.systeme_ventilationData.type_ventilation === "A" ? "E" : "F";
      this.selected = this.systeme_ventilationData.type_installation === "Installation individuelle" ? "B" : "A";

      this.selectedNorme=this.systeme_ventilationData.type==="VMBP" ? "D":"C"
    }
  },
  computed: {
    ...mapState({
    token: (state) => state.token,
  }),
    ...mapGetters(["getdevisMonoFiche"]),
  },
  watch: {
    getdevisMonoFiche: {
      handler(newDevis) {
        if (newDevis && newDevis.data) {
          this.systeme_ventilationData = this.initializesysteme_ventilation();
          this.systeme_ventilation = newDevis.data?.devis.systeme_ventilation_mecanique_controlee !== null;
          if (this.systeme_ventilation) {
            this.isCompleted = true;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["setDevisMonoFiche"]),
  
    onKeyDownFloat(event) {
      preventExceedingLengthFloat(event);
    },
    onKeyDown(event) {
      preventExceedingLength(event);
    },
    
    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
validateInput(event) {
    let value = event.target.value;
    value = value.replace(/[^0-9.]/g, '');
    if (value.startsWith('.')) {
      value = '0' + value;
    }
    if (value && parseFloat(value) > 1) {
      value = '1';
    }
    this.systeme_ventilationData.puissance_electrique = value;
  }, 
    async postsystemeVentilation(payload) {
     
     try {
       const response = await axios.post(
         domain + `/ventillation_mecanique_controlee`,
         payload,
         {
           headers: {
             Authorization: `Bearer ` + this.token,
           },
         }
       );
  
       return response.data.data
     } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
      this.errorMessage = error.response.data.message;
    } else if (error.response && error.response.data && error.response.data.errors) {
      this.errorMessage = error.response.data.errors.join(', ');
    } else {
      this.errorMessage = "An unknown error occurred.";
    }
    this.$refs.snackbar.showSnackbar(this.errorMessage);
    return null;
  
     }
 },
 
 async putsystemeVentilation(payload) {
  
  try {
    const response = await axios.put(
      domain + `/ventillation_mecanique_controlee/${payload.id}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ` + this.token,
        },
      }
    );
    
return response.data.data
  } catch (error) {

    if (error.response && error.response.data && error.response.data.message) {
      this.errorMessage = error.response.data.message;
    } else if (error.response && error.response.data && error.response.data.errors) {
      this.errorMessage = error.response.data.errors.join(', ');
    } else {
      this.errorMessage = "An unknown error occurred.";
    }
    this.$refs.snackbar.showSnackbar(this.errorMessage);
    return null;
  
  }
},

    initializesysteme_ventilation() {
      const systeme_ventilation = this.getdevisMonoFiche?.data?.devis.systeme_ventilation_mecanique_controlee || {};
      return {
        id: systeme_ventilation.id,
        puissance_electrique: systeme_ventilation.puissance_electrique || "",
        nombre_logements: systeme_ventilation.nombre_logements || "",
        classe_energetique: systeme_ventilation.classe_energetique || "",
        type_installation: systeme_ventilation.type_installation || "",
        surface_habitable: systeme_ventilation.surface_habitable || "",
        type:systeme_ventilation.type||"",
        type_ventilation: systeme_ventilation.type_ventilation || "",
      };
    },
    async chauffeAdd() {


  if (this.selected === "B") {
    this.systeme_ventilationData.type_installation = "Installation individuelle";
    this.systeme_ventilationData.type = "VMC";
    if (this.systeme_ventilationData.puissance_electrique <= 0.15) {
      this.systeme_ventilationData.caissonType = "Caisson basse consommation";
    } else {
      this.systeme_ventilationData.caissonType = "Caisson standard";
    }
    if (this.selectedType === "E") {
    this.systeme_ventilationData.type_ventilation = "A";
  } else {
    this.systeme_ventilationData.type_ventilation = "B";
  }

  } else if(this.selected==="A"){
    this.systeme_ventilationData.type_installation = "Installation collective";
    if (this.selectedType === "E") {
    this.systeme_ventilationData.type_ventilation = "A";
  } else {
    this.systeme_ventilationData.type_ventilation = "B";
  }

    if (this.selectedNorme === "C") {
      this.systeme_ventilationData.type = "VMC";
    } else {
      this.systeme_ventilationData.type = "VMBP";
    }
    if (this.systeme_ventilationData.puissance_electrique <= 0.10) {
      this.systeme_ventilationData.caissonType = "Caisson basse consommation";
    } else if (this.systeme_ventilationData.puissance_electrique <= 0.12) {
      this.systeme_ventilationData.caissonType = "Caisson basse pression";
    } else {
      this.systeme_ventilationData.caissonType = "Caisson standard";
    }
  }
 
  const response = await this.postsystemeVentilation(this.systeme_ventilationData);
      
  if(response.id!==null){
  this.getdevisMonoFiche.data.devis.systeme_ventilation_mecanique_controlee_id = response.id;
  this.getdevisMonoFiche.data.devis.systeme_ventilation_mecanique_controlee = {
    id: response.id,
    type_ventilation: this.systeme_ventilationData.type_ventilation,
    type_installation: this.systeme_ventilationData.type_installation,
    surface_habitable: this.systeme_ventilationData.surface_habitable,
    classe_energetique: this.systeme_ventilationData.classe_energetique,
    puissance_electrique: this.systeme_ventilationData.puissance_electrique,
    nombre_logements: this.systeme_ventilationData.nombre_logements,
    type:this.systeme_ventilationData.type,
  };
  this.setDevisMonoFiche(this.getdevisMonoFiche);

  return response; 
}else{
         
         this.isLoading=false;
 
         return
       }
},
    async systeme_ventilationAdd() {

      if (this.systeme_ventilation) {
    
      
        this.systeme_ventilationData.type_ventilation = this.selectedType === "E" ? "A" : "B";
        
        this.systeme_ventilationData.type=this.selectedNorme=== "C" ? "VMC":"VMBP";
        this.systeme_ventilationData.type_installation = this.selected === "A" ? "Installation collective" : "Installation individuelle";

       if(this.selected==="B"){
        this.systeme_ventilationData.nombre_logements=0;
       }
       if(this.selected==="A"){
        this.systeme_ventilationData.classe_energetique="";
        this.systeme_ventilationData.surface_habitable=0;
       }
       const chauffe = {
          ...this.systeme_ventilationData,
          id: this.getdevisMonoFiche.data.devis.systeme_ventilation_mecanique_controlee.id,
        };
    
        const reponse = await this.putsystemeVentilation(chauffe);
        this.getdevisMonoFiche.data.devis.systeme_ventilation_mecanique_controlee = {
          id: this.getdevisMonoFiche.data.devis.systeme_ventilation_mecanique_controlee.id,
          type_ventilation: this.systeme_ventilationData.type_ventilation,
          type_installation: this.systeme_ventilationData.type_installation,
          surface_habitable: this.systeme_ventilationData.surface_habitable,
          classe_energetique: this.systeme_ventilationData.classe_energetique,
          puissance_electrique: this.systeme_ventilationData.puissance_electrique,
          nombre_logements:this.systeme_ventilationData.nombre_logements,
          type:this.systeme_ventilationData.type,
        };

        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return reponse;
      }
      const res = await this.chauffeAdd();
      this.getdevisMonoFiche.data.devis.systeme_ventilation_mecanique_controlee_id = res.id;
      this.getdevisMonoFiche.data.devis.systeme_ventilation_mecanique_controlee = {
        id: res.id,
        type_ventilation: this.systeme_ventilationData.type_ventilation,
        type_installation: this.systeme_ventilationData.type_installation,
        surface_habitable: this.systeme_ventilationData.surface_habitable,
        classe_energetique: this.systeme_ventilationData.classe_energetique,
        puissance_electrique: this.systeme_ventilationData.puissance_electrique,
        nombre_logements: this.systeme_ventilationData.nombre_logements,
        type:this.systeme_ventilationData.type,
      };
      this.setDevisMonoFiche(this.getdevisMonoFiche);
      return res;
    },
    async goToStep() {
      this.isLoading=true;
    
      await this.systeme_ventilationAdd();
      if(this.errorMessage!==''){
     
     this.isLoading=false;
   
     this.$refs.snackbar.showSnackbar("Toutes les données sont requises.");
        this.isCompleted = false;
     return
   }
      this.isLoading=false;
      this.isCompleted = true;
 
    },
    async goToNextStep() {
      this.isLoading=true;
    
      await this.systeme_ventilationAdd();
      if(this.errorMessage!==''){
     
     this.isLoading=false;
     this.$refs.snackbar.showSnackbar("Toutes les données sont requises.");

     return
   }
      this.isLoading=false;
      this.$emit("changeStep", 6, true);
    },
    goToPrevStep() {
      this.$emit("changeStep", 4);
    },
  },
};
</script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
