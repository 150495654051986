<template>
    <v-snackbar
      v-model="visible"
      :timeout="timeout"
      :color="color"
      rounded="pill"
      bottom
      :style="{ left: '50%', transform: 'translateX(-50%)' }"
    >
    &#x274C; {{ message }}
    </v-snackbar>
  </template>
  
  <script>
  export default {
    data() {
      return {
        visible: false,
        message: '',
        timeout: 5000,
        color: 'error', 
      };
    },
    methods: {
      showSnackbar(message, color = 'error', timeout = 5000) {
        this.message = message;
        this.color = color;
        this.timeout = timeout;
        this.visible = true;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Ajouter des styles personnalisés si nécessaire */
  </style>
  
  

  