var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"row my-4 mx-1 justify-content-center"},[(_vm.progress)?_c('v-container',{staticClass:"loding",attrs:{"type":"image"}},[_c('v-skeleton-loader',{attrs:{"type":"image"}})],1):_c('div',{staticClass:"col-lg-8 col-sm-12 col-md-10 borderPointier"},[_c('div',{staticClass:"background-white"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"position"},[_vm._v(" "+_vm._s(_vm.$t("conditions_technique"))+" ")])])]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.fenetre_vitrage_completAdd.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('type_fenetre'),"label-for":"select-type-appareil"}},[_c('b-form-select',{staticClass:"custom-input",attrs:{"options":_vm.type_fenetreOptions,"id":"select-type-appareil"},model:{value:(_vm.fenetre_vitrage_completData.type_fenetre),callback:function ($$v) {_vm.$set(_vm.fenetre_vitrage_completData, "type_fenetre", $$v)},expression:"fenetre_vitrage_completData.type_fenetre"}})],1),_c('div',{staticClass:"fieldset mb-2"},[_c('div',{staticClass:"legend mb-2"},[_vm._v(" "+_vm._s(_vm.$t("nombre_fenetres"))+" ")]),_c('b-form-input',{staticClass:"form-input custom-placeholder custom-input",attrs:{"type":"number","min":"0","placeholder":_vm.$t('nombre_fenetres')},on:{"paste":(event) =>
                  _vm.onPaste(
                    event,
                    'fenetre_vitrage_completData.nombre_fenetres'
                  ),"keydown":(event) => _vm.onKeyDownInt(event)},model:{value:(_vm.fenetre_vitrage_completData.nombre_fenetres),callback:function ($$v) {_vm.$set(_vm.fenetre_vitrage_completData, "nombre_fenetres", $$v)},expression:"fenetre_vitrage_completData.nombre_fenetres"}})],1),_c('div',{staticClass:"fieldset mb-2"},[_c('div',{staticClass:"legend mb-2"},[_vm._v(" "+_vm._s(_vm.$t("surface"))+" ")]),_c('b-form-input',{staticClass:"form-input custom-placeholder custom-input",attrs:{"type":"number","min":"0","placeholder":_vm.$t('surface')},on:{"keydown":(event) => _vm.onKeyDown(event),"paste":(event) =>
                  _vm.onPaste(event, 'fenetre_vitrage_completData.surface_totale')},model:{value:(_vm.fenetre_vitrage_completData.surface_totale),callback:function ($$v) {_vm.$set(_vm.fenetre_vitrage_completData, "surface_totale", $$v)},expression:"fenetre_vitrage_completData.surface_totale"}})],1),_c('div',{staticClass:"fieldset mb-2"},[_c('div',{staticClass:"legend mb-2"},[_vm._v(" "+_vm._s(_vm.$t("coefficient_transmission"))+" ")]),_c('b-form-input',{staticClass:"form-input custom-placeholder custom-input",attrs:{"type":"number","min":"0","placeholder":_vm.$t('coefficient_transmission')},on:{"keydown":(event) => _vm.onKeyDown(event),"paste":(event) =>
                  _vm.onPaste(
                    event,
                    'fenetre_vitrage_completData.coefficient_transmission'
                  )},model:{value:(_vm.fenetre_vitrage_completData.coefficient_transmission),callback:function ($$v) {_vm.$set(_vm.fenetre_vitrage_completData, "coefficient_transmission", $$v)},expression:"fenetre_vitrage_completData.coefficient_transmission"}})],1),_c('div',{staticClass:"fieldset mb-2"},[_c('div',{staticClass:"legend mb-2"},[_vm._v(" "+_vm._s(_vm.$t("facteur_solaire"))+" ")]),_c('b-form-input',{staticClass:"form-input custom-placeholder custom-input",attrs:{"type":"number","min":"0","placeholder":_vm.$t('facteur_solaire')},on:{"keydown":(event) => _vm.onKeyDown(event),"paste":(event) =>
                  _vm.onPaste(
                    event,
                    'fenetre_vitrage_completData.facteur_solaire'
                  )},model:{value:(_vm.fenetre_vitrage_completData.facteur_solaire),callback:function ($$v) {_vm.$set(_vm.fenetre_vitrage_completData, "facteur_solaire", $$v)},expression:"fenetre_vitrage_completData.facteur_solaire"}})],1)],1),(_vm.show)?_c('div',{staticClass:"d-flex justify-content-end mt-4"},[_c('b-button',{staticClass:"button-suivant-style m-3",attrs:{"type":"submit"},on:{"click":_vm.goToStep}},[(_vm.isLoading)?[_c('div',{staticClass:"loading ml-2"},[_c('div',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}})])]:_vm._e(),[_vm._v(" "+_vm._s(_vm.$t("CONFIRMER"))+" ")]],2)],1):_vm._e()])],1)]),(!_vm.show)?_c('div',{staticClass:"d-flex justify-content-end mt-4"},[_c('b-button',{staticClass:"button-pre-style m-3",on:{"click":_vm.goToPrevStep}},[_vm._v(" "+_vm._s(_vm.$t("PREVIOUS"))+" ")]),_c('b-button',{staticClass:"button-suivant-style m-3",attrs:{"type":"submit"},on:{"click":_vm.goToNextStep}},[_vm._v(" "+_vm._s(_vm.$t("NEXT"))+" "),(_vm.isLoading)?_c('div',{staticClass:"loading ml-2"},[_c('div',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}})]):_vm._e(),(_vm.isLoadingButton)?_c('div',{staticClass:"loading ml-2"},[_c('div',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}})]):_vm._e()])],1):_vm._e(),_c('Snackbar',{ref:"snackbar"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }