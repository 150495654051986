<template>
  <div>
    <!-- Tabs -->
  
    <v-tabs v-if="visibleTabs.length > 1" v-model="step" bg-color="deep-purple-darken-4" class="justify-center"  center-active>
      <v-tab v-for="(tab, index) in visibleTabs" :key="index" class="mb-3" >
        {{ tab }}
      </v-tab>
    </v-tabs>
    <!-- Dynamic Content -->
    <div v-for="(tab, index) in visibleTabs" :key="index" class="">
      <div
        v-if="step === index"
        class="d-flex justify-content-center align-items-center flex-column"
      >
        <div class="col-lg-12 col-sm-12 col-md-10 borderPointier mb-2">
          <div class="background-white">
            <div class="title text-center">
              <div class="position">{{ $t("chantier") }} - {{ tab }}</div>
            </div>
          </div>

          <!-- Form -->
          <form class="my-4 w-100">
            <div class="center">
              <div class="d-flex justify-content-end mt-4 px-0">
                <b-dropdown
                  :text="$t('ADD')"
                  class="button-pre-style mb-2"
                  variant="primary"
                  right
                >
                  <b-dropdown-item @click="addRow('Product', step)"  
                  :disabled="disabledGroups[index]">
                    {{ $t("PRODUIT") }}</b-dropdown-item >
                  <b-dropdown-item @click="addRow('Forfait', step)">{{
                    $t("FORFAIT")
                  }}</b-dropdown-item>
                  <b-dropdown-item @click="addRow('Main d\'oeuvre', step)">{{
                    $t("MAIN_D_OEUVRE")
                  }}</b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="d-flex justify-content-center">
                <v-container v-if="progress" type="image" class="loding">
                  <v-skeleton-loader type="image"></v-skeleton-loader>
                </v-container>
                <div v-else class="border">
                  <div class="scroll-container">
                    <div class="content-wrapper">
                      <!--header-->

                      <div class="header d-flex mb-3 px-3 pt-2">
                        <div class="header-item" style="flex: 1.5">
                          {{ $t("PRODUIT") }}
                        </div>
                        <div class="header-item" style="flex: 1">
                          {{ $t("REF") }}
                        </div>
                        <div class="header-item" style="flex: 3">
                          {{ $t("WORDING") }}
                        </div>
                        <div class="header-item" style="flex: 1">
                          {{ $t("QTE") }}
                        </div>
                        <div class="header-item" style="flex: 1.5">
                          {{ $t("type_uniteU") }}
                        </div>
                        <div class="header-item" style="flex: 1.5">
                          {{ $t("PUHT") }}
                        </div>
                        <div class="header-item" style="flex: 1">
                          {{ $t("TOTALHT") }}
                        </div>
                        <div
                          class="header-item"
                          style="flex: 1; border-radius: 0 5px 0 0"
                        >
                          {{ $t("VAT") }}
                        </div>
                      </div>
                      <!--section vide-->
                      <div
                        v-if="
                          !getdevisMonoFiche.data.groupeLigneDocument[step]
                            ?.products?.length
                        "
                        class="text-center my-4"
                      >
                        {{ $t("NO_DATA_FOUND") }}
                      </div>

                      <!--section des produits-->
                      <div v-else>
                        <div
                          v-for="(row, productIndex) in getdevisMonoFiche.data
                            .groupeLigneDocument[step]?.products || []"
                          :key="productIndex"
                          class="row-item d-flex"
                        >
                        
                          <form @submit.prevent="handleSubmit">
                            <div class="row m-2">
                              <div class="input-wrapper mx-2" style="flex: 2">
                                <div v-if="row.type === 'Product'">
                                  <button
                                    class="mb-1 py-0 mr-1 newstore rounded"
                                    @click="openModal(row.type)"
                                  >
                                    +
                                  </button>
                                  <articlemodale />
                                </div>
                                <div v-if="row.type === 'Forfait'">
                                  <button
                                    class="mb-1 py-0 mr-1 newstore rounded"
                                    @click="openModal(row.type)"
                                  >
                                    +
                                  </button>
                                  <forfaitmodale />
                                </div>
                                <div v-if="row.type === 'Main d\'oeuvre'">
                                  <button
                                    class="mb-1 py-0 mr-1 newstore rounded"
                                    @click="openModal(row.type)"
                                  >
                                    +
                                  </button>
                                  <mainoeuvremodale />
                                </div>
                                <searchInput
                                  v-if="row.type === 'Product'"
                                  :list="getAllProducts"
                                  label="lib"
                                  :placeholder="$t('PRODUIT')"
                                  :searchFunc="all_products"
                                  @searchfilter="
                                    addProduit($event, productIndex, step)
                                  "
                                  :filtre="{ entreprise: form.id }"
                                
                                  class="truncated-label"
                                ></searchInput>
                                <searchInput
                                  v-if="row.type === 'Forfait'"
                                  :list="getAllforfaits"
                                  label="lib"
                                  :placeholder="$t('FORFAIT')"
                                  :searchFunc="all_forfaits"
                                  @searchfilter="
                                    addProduit($event, productIndex, step)
                                  "
                                  :filtre="{ entreprise: form.id }"
                                ></searchInput>

                                <searchInput
                                  v-if="row.type === 'Main d\'oeuvre'"
                                  :list="getAllmainoeuvres"
                                  label="lib"
                                  :placeholder="$t('MAIN_')"
                                  :searchFunc="all_mainoeuvres"
                                  @searchfilter="
                                    addProduit($event, productIndex, step)
                                  "
                                  :filtre="{ entreprise: form.id }"
                                ></searchInput>
                              </div>
                              <div class="input-wrapper mx-2" style="flex: 1">
                                <b-form-input
                                  v-model="row.ref"
                                  :disabled="(row.lib !=='')"

                                  maxlength="10"
                                  class="custom-input"
                                />
                              </div>
                              <div class="input-wrapper ms-2" style="flex: 3">
                                <b-form-input
                                  class="custom-input"
                                  v-model="row.lib"
                                  @input="row.lib = $event.toUpperCase()"
                                  required
                                  maxlength="100"
                                />
                              </div>
                              <div class="input-wrapper mx-2" style="flex: 1">
                                <b-form-input
                                  class="custom-input"
                                  type="number"
                                  @paste="
                                    (event) => onPaste(event, 'row.quantite')
                                  "
                                  v-model.number="row.quantite"
                                  @keydown="(event) => onKeyDown(event)"
                                  min="1"
                                  required
                                />
                              </div>
                              <div class="input-wrapper mx-2" style="flex: 1.5">
                                <b-form-select
                                  class="custom-input"
                                  v-model="row.type_unite"
                                  :options="optionstype"
                                  text-field="text"
                                  value-field="value"
                                  required
                                />
                              </div>
                              <div class="input-wrapper mx-2" style="flex: 1.5">
                                <b-form-input
                                  class="custom-input"
                                  type="number"
                                  @paste="
                                    (event) => onPaste(event, 'row.unit_price')
                                  "
                                  v-model.number="row.unit_price"
                                  @keydown="(event) => onKeyDown(event)"
                                  required
                                  step="1"
                                  min="0"
                                  max="10000000.00"
                                />
                              </div>
                              <div class="input-wrapper mx-2" style="flex: 1.5">
                                <b-form-input
                                  class="custom-input"
                                  :value="totalHT(row)"
                                  readonly
                                />
                              </div>
                              <div class="input-wrapper mx-2" style="flex: 1">
                                <b-form-select
                                  class="custom-input"
                                  v-model.number="row.tva"
                                  :options="optionsTVA"
                                  required
                                />
                              </div>
                            </div>

                            <div class="row input-wrapper m-2">
                              <div style="flex: 0.5"></div>
                              <div class="mx-2" style="flex: 0.5">
                                {{ $t("DESC") }}
                              </div>
                            </div>
                            <div class="row input-wrapper m-2">
                              <div style="flex: 2"></div>
                              <div style="flex: 12">
                                <b-form-textarea
                                  class="custom-input"
                                  v-model="row.desc"
                                  :placeholder="$t('AJUTER_DESCRIPTION')"
                                  rows="5"
                                ></b-form-textarea>
                              </div>
                            </div>
                            <div class="row m-2">
                              <div style="flex: 13"></div>
                              <div style="flex: 1">
                                <b-button
                                  @click="removeRow(step, productIndex)"
                                  class="button-pre-style custom-input"
                                >
                                  <b-icon icon="trash"></b-icon>
                                </b-button>
                              </div>
                            </div>

                            <hr
                              v-if="
                                index <
                                  getdevisMonoFiche.data
                                  .groupeLigneDocument[step]?.products.length -
                                  1
                              "
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-4">
              <b-button
                @click="goToStep(step)"
                class="button-suivant-style m-3"
              >
                <template v-if="isLoading">
                  <div class="loading ml-2">
                    <div
                      class="spinner-border spinner-border-sm"
                      role="status"
                    ></div>
                  </div>
                </template>
                <!-- <template v-else-if="isCompleted">
                  {{ $t("EDIT") }}
                </template> -->
                <template >
                  {{ $t("CONFIRMER") }}
                </template>
              </b-button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        {{ $t("NEXT") }}
      
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <Snackbar ref="snackbar" class="mt-5" />
  </div>
</template>
<script>
import { handlePaste, preventExceedingLengthFloat } from "@/utils/inputUtils";
import articlemodale from "@/components/Ajoutmodel/articlemodale.vue";
import searchInput from "@/components/ui/searchInput";
import mainoeuvremodale from "@/components/Ajoutmodel/mainoeuvremodale"
import forfaitmodale from "@/components/Ajoutmodel/forfaitmodale"
import { mapGetters, mapActions } from "vuex";
import Snackbar from "@/components/ui/errorSnackbar.vue";

export default {
  components: {
    Snackbar,
    articlemodale,
    searchInput,
    mainoeuvremodale,
    forfaitmodale,

  },
  props: {
    show: {
      type: Boolean,
      default: false,
      required: false,
    },
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },

    produitIds: {
      type: Array,
    },
  },
  created() {
    this.all_mainoeuvres({ page: this.page, perPage: 100000 });
    this.all_products({ page: this.page, perPage: 100000 });
    this.all_forfaits({ page: this.page, perPage: 100000 });
 this.checkProductsInGroups()
  },
  data() {
    return {
      errorMessage:'',
      disabledGroups: [] ,
      addedProducts: [],
    
      stepsData: [],
      isLoading: false,
      step: 0,
      form: {},
      optionstype: [
        { value: "m2", text: this.$t("m2") },
        { value: "m3", text: this.$t("m3") },
        { value: "ml", text: this.$t("ML") },
        { value: "h", text: this.$t("h") },
        { value: "forfait", text: this.$t("FORFAIT") },
        { value: "unitaire", text: this.$t("unitaire") },
      ],
      optionsTVA: [
        { value: 0, text: "0%" },
        { value: 5.5, text: "5.5%" },
        { value: 10, text: "10%" },
        { value: 20, text: "20%" },
      ],
      tabs: [
        "Isolation de combles ou de toiture",
        "Isolation des murs",
        "Isolation d’un plancher ",
        "Fenêtre ou porte-fenêtre complète avec vitrage isolant",
        "Pompe à chaleur de type air/air ",
        "Ventilation mécanique simple flux hygroréglable",
        "Pompe à chaleur de type air/eau",
        "Chauffe-eau thermodynamique à accumulation",
        "Système solaire combiné",
        "Système de régulation par programmation horaire pièce par pièce",
        "Chaudière biomasse individuelle",
        "Raccordement d’un bâtiment résidentiel à un réseau de chaleur",
        "Appareil indépendant de chauffage au bois",
        "Chauffe-eau solaire individuel",
      ],
      stepActiv: [
        "Isolation des murs",
        "Pompe à chaleur de type air/eau",
        "Isolation de combles ou de toiture",
      ],
    };
  },
  watch: {
    
    getdevisMonoFiche: {
      handler: "checkProductsInGroups",
      immediate: true
    }
  },

  computed: {
    ...mapGetters([
      "getAllforfaits",
      "getAllProducts",
      "getAllmainoeuvres",
      "getOnlineUser",
      "getdevisMonoFiche",
    ]),

    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    visibleTabs() {
  const groupeLigneDocument = this.getdevisMonoFiche?.data?.groupeLigneDocument || [];
  
  const typeGroups = groupeLigneDocument.map((item) => item.type_group);

  return typeGroups;
}
  },
  methods: {
    ...mapActions([
      "deleteProduitOfGroup",
      "createoneProductOfGroup",
      "updateProduct",
      "setDevisMonoFiche",
      "update_devis_group_BAR",
      "all_products",
      "all_mainoeuvres",
      "all_forfaits",
     
    ]),
    checkProductsInGroups() {
  
      this.disabledGroups = this.getdevisMonoFiche.data.groupeLigneDocument.map(group => {
        const products = group.products || [];

        return products.some(product => product.fabricant !== null);
      });
 
    },
    async removeRow(step, index) {

      const rowToRemove =
        this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products[index]
          ?.id;

      if (!rowToRemove) {
        this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products.splice(
          index,
          1
        );
      }

      if (rowToRemove) {
        await this.deleteProduitOfGroup(rowToRemove);
        this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products.splice(
          index,
          1
        );
      }

      this.addedProducts = this.addedProducts.filter(
        (item) => !(item.step === step && item.index === index)
      );

      this.addedProducts = this.addedProducts.map((item) => {
        if (item.step === step && item.index > index) {
          return { step: item.step, index: item.index - 1 };
        }
        return item;
      });
     this.checkProductsInGroups()
      this.setDevisMonoFiche(this.getdevisMonoFiche);

    },
    addProduit(selectedItem, index, step) {
      const productType =
        this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products[index]
          .type;
     
      let updatedProduct = null;

      if (productType === "Product") {
        const product = this.getAllProducts.find(
          (p) => p.id === selectedItem.id
        );
        updatedProduct = {
          ...this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products[
            index
          ],

          ref: product.ref,
          lib: product.lib,
          type_ligne: product.type_ligne,
          unit_price: product.unit_price,
          type_unite: product.type_unite,
          tva: product.tva,
          desc: product.desc,
          searchInputValue: product,
          produit_id: product.id,
          totalHT: product.unit_price * product.quantite,
        };
      } else if (productType === "Forfait") {
        const forfait = this.getAllforfaits.find(
          (f) => f.id === selectedItem.id
        );
        updatedProduct = {
          ...this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products[
            index
          ],
          ref: forfait.ref,
          lib: forfait.lib,
          unit_price: forfait.unit_price,
          type_unite: forfait.type_unite,
          tva: forfait.tva,
          type_ligne: forfait.type_ligne,
          desc: forfait.desc,
          searchInputValue: forfait,
          produit_id: forfait.id,
          totalHT: forfait.unit_price * forfait.quantite,
        };
      } else if (productType === "Main d'oeuvre") {
        const mainoeuvre = this.getAllmainoeuvres.find(
          (m) => m.id === selectedItem.id
        );
        updatedProduct = {
          ...this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products[
            index
          ],
          ref: mainoeuvre.ref,
          lib: mainoeuvre.lib,
          desc:
            mainoeuvre.desc +
            ` La preuve de la réalisation de l’opération mentionne :
- la mise en place d’une isolation thermique d’un plancher bas ;
- les marque et référence ainsi que l’épaisseur et la surface d’isolant installé ;
- la résistance thermique de l'isolation mise en place évaluée, suivant la nature de l’isolant, selon l’une des normes
susvisées ;
- les aménagements nécessaires à la mise en place de l’isolation (coffrage ou écran de protection autour des conduits
de fumées et des dispositifs d’éclairage encastrés ; rehausse rigide au niveau de la trappe d’accès ; pare-vapeur ou
tout autre dispositif équivalent lorsqu'il est nécessaire de protéger les matériaux d'isolation thermique contre les
transferts d'humidité pour garantir la performance de l'ouvrage) ;
- la date de la visite technique préalable par le professionnel`,
          unit_price: mainoeuvre.unit_price,
          type_ligne: mainoeuvre.type_ligne,
          type_unite: mainoeuvre.type_unite,
          tva: mainoeuvre.tva,
          searchInputValue: mainoeuvre,
          produit_id: mainoeuvre.id,
          totalHT: mainoeuvre.unit_price * mainoeuvre.quantite,
        };
      }

      if (updatedProduct) {
        this.$set(
          this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products,
          index,
          updatedProduct
        );
        this.selected = true;
        this.setDevisMonoFiche(this.getdevisMonoFiche);

        this.addedProducts.push({ step, index });
      }
    },

    onKeyDown(event) {
      preventExceedingLengthFloat(event);
    },

    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
    openModal(type) {
   
     if(type==='Product'){
      this.$root.$emit("bv::show::modal", "ProductModal");
     }
     if(type==='Forfait'){
      this.$root.$emit("bv::show::modal", "forfaitModal");
     }
     if(type==="Main d'oeuvre"){
      this.$root.$emit("bv::show::modal", "mainoeuvreModal");
     }
    },
    isProductAdded(step) {
     const produit=this.getdevisMonoFiche.data.groupeLigneDocument[step].products.some(
      product => product.type === "Product"
    );
    return produit
  },
    addRow(type, step) {
      const groupe = this.getdevisMonoFiche.data.groupeLigneDocument[step];

if (type === "Product" && groupe.products.some(p => p.type === "Product")) {
  return; 
}
      if (type === "Product") {
        this.getdevisMonoFiche.data.groupeLigneDocument[step].products.push({
          groupelignedocument_id:
            this.getdevisMonoFiche.data.groupeLigneDocument[step].id,
          type: "Main d'oeuvre",
          ref: "",
          lib: "",
          quantite: 1,
          type_unite: null,
          unit_price: 0,
          tva: 0,
          desc: "",
          searchInputValue: null,
        });
      }
      this.getdevisMonoFiche.data.groupeLigneDocument[step].products.push({
        groupelignedocument_id:
          this.getdevisMonoFiche.data.groupeLigneDocument[step].id,

        type: type,
        ref: "",
        lib: "",
        quantite: 1,
        type_unite: null,
        unit_price: 0,
        tva: 0,
        desc: "",
        searchInputValue: null,
      });
      this.checkProductsInGroups();
    },
    async goToStep(step) {
      this.isLoading=true
      const filteredProducts = this.addedProducts.filter(
        (item) => item.step === step
      );
      for (let i = 0; i < filteredProducts.length; i++) {
  const item = filteredProducts[i];
  const product = this.getdevisMonoFiche.data.groupeLigneDocument[item.step]?.products[item.index];

  if (product) {
    const groupelignedocumentId = String(product.groupelignedocument_id);
    const produitId = String(product.produit_id);

    if (product.id) {
    
      await this.updateProduct(product);
    } else {
    
      await this.createoneProductOfGroup({
        ...product,
        groupelignedocument_id: groupelignedocumentId,
        produit_id: produitId,
      });
    }

    const indexInAddedProducts = this.addedProducts.findIndex(
      (addedProduct) =>
        addedProduct.step === item.step &&
        addedProduct.index === item.index
    );

    if (indexInAddedProducts !== -1) {
      this.addedProducts.splice(indexInAddedProducts, 1);
    }
  }
}

    
      this.isLoading=false
    },
    valideDevis() {
    const groupeLigneDocuments =
        this.getdevisMonoFiche.data.groupeLigneDocument || [];

    for (const ligne of groupeLigneDocuments) {
        const typeGroup = ligne.type_group;
        const typeValid =
            (typeGroup === "BAR-EN-101" && ligne.products.length > 0) ||
            (typeGroup === "BAR-EN-102" && ligne.products.length > 0) ||
            (typeGroup === "BAR-EN-103" && ligne.products.length > 0) ||
            (typeGroup === "BAR-TH-171" && ligne.products.length > 0) ||
            (typeGroup === "BAR-TH-143" && ligne.products.length > 0) ||
            (typeGroup === "BAR-TH-101" && ligne.products.length > 0) ||
            (typeGroup === "BAR-TH-113" && ligne.products.length > 0) ||
            (typeGroup === "BAR-TH-112" && ligne.products.length > 0) ||
            (typeGroup === "BAR-TH-127" && ligne.products.length > 0) ||
            (typeGroup === "BAR-TH-129" && ligne.products.length > 0) ||
            (typeGroup === "BAR-TH-148" && ligne.products.length > 0) ||
            (typeGroup === "BAR-TH-137" && ligne.products.length > 0) ||
            (typeGroup === "BAR-EN-104" && ligne.products.length > 0) ||
            (typeGroup === "BAR-TH-175" && ligne.products.length > 0) ||
            (typeGroup === "BAR-EN-174" && ligne.products.length > 0) ||
            (typeGroup === "BAR-TH-173" && ligne.products.length > 0);
    
        if (!typeValid) {
            this.errorMessage = 'Il faut confirmer toutes les étapes et s\'assurer que chaque groupe a des produits.';
            break;
        }
    } 
},
    async goToNextStep() {
      this.valideDevis()
      if (this.errorMessage !== "") {
        this.$refs.snackbar.showSnackbar(this.errorMessage);
        this.isLoading = false;
        return;
    }
      this.$emit("changeStep", 7, true);
    },
    totalHT(row) {
      if (row.unit_price && row.quantite) {
        return (row.unit_price * row.quantite).toFixed(2);
      }
      return 0;
    },
    goToPrevStep() {
      this.$emit("changeStep", 5);
    },
  },
};
</script>

<style>

.tabs-container {
  display: flex;
  justify-content: center; /* Centre le composant horizontalement */
}
.newstore {
  background: #24316d;
  padding: 8px 12px;
  color: #fff;
  cursor: pointer;
  width: 30px;
  height: 30px;
  &:hover {
    background-color: #24316d !important;
  }
}
.truncated-label .label-class {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10px;
}
b-dropdown {
  background-color: #24316d !important;
}
.btn-secondary {
  color: #fff;
  background-color: #24316d !important;
  border-color: #24316d !important;
}
.header-item {
  flex: 1;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
}

.btn-primary.dropdown-toggle {
  font-size: 14px;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}
.input-wrapper:not(:last-child) {
  margin-right: 10px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
  width: 100%;
}
.button-pre-style {
  background-color: #24316d !important;
  box-shadow: none;
  border-radius: 5px 5px 5px 5px;
}
.border {
  border: 1px solid #24316d !important;
}
.custom-input {
  box-shadow: none !important;
  width: 100%;
  height: 38px;
}

.row-item {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
  background-color: #24316d;
  color: white;
}

.border {
  display: block;
  width: 100%;
  border: 1px solid #24316d;
  margin-top: 20px;
  overflow-x: auto;
  box-sizing: border-box;
}

.scroll-container {
  overflow-x: auto;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
.content-wrapper {
  min-width: 1000px;
}

.header,
.row-item {
  display: flex;
  min-width: 100px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  margin: 5px;
}
</style>
