<template>
  <div>
    <div>
      <div class="row my-4 mx-1 justify-content-center">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <div v-else class="col-lg-8 col-sm-12 col-md-10 borderPointier">
          <div class="background-white">
            <div class="title">
              <div class="position">
                {{ $t("conditions_technique") }} 
              </div>
            </div>
          </div>
          <form @submit.prevent="fenetre_vitrage_completAdd">
            <b-form-group
              :label="$t('type_fenetre')"
              label-for="select-type-appareil"
            >
              <b-form-select
                v-model="fenetre_vitrage_completData.type_fenetre"
                :options="type_fenetreOptions"
                id="select-type-appareil"
                class="custom-input"
              >
              </b-form-select>
            </b-form-group>

            <div class="fieldset mb-2">
              <div class="legend mb-2">
                {{ $t("nombre_fenetres") }}
              </div>
              <b-form-input
                type="number"
                min="0"
                @paste="
                  (event) =>
                    onPaste(
                      event,
                      'fenetre_vitrage_completData.nombre_fenetres'
                    )
                "
                @keydown="(event) => onKeyDownInt(event)"
                v-model="fenetre_vitrage_completData.nombre_fenetres"
                :placeholder="$t('nombre_fenetres')"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-input>
            </div>
            <div class="fieldset mb-2">
              <div class="legend mb-2">
                {{ $t("surface") }}
              </div>
              <b-form-input
                type="number"
                min="0"
                @keydown="(event) => onKeyDown(event)"
                @paste="
                  (event) =>
                    onPaste(event, 'fenetre_vitrage_completData.surface_totale')
                "
                v-model="fenetre_vitrage_completData.surface_totale"
                :placeholder="$t('surface')"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-input>
            </div>
            <div class="fieldset mb-2">
              <div class="legend mb-2">
                {{ $t("coefficient_transmission") }}
              </div>
              <b-form-input
                type="number"
                min="0"
                @keydown="(event) => onKeyDown(event)"
                @paste="
                  (event) =>
                    onPaste(
                      event,
                      'fenetre_vitrage_completData.coefficient_transmission'
                    )
                "
                v-model="fenetre_vitrage_completData.coefficient_transmission"
                :placeholder="$t('coefficient_transmission')"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-input>
            </div>
            <div class="fieldset mb-2">
              <div class="legend mb-2">
                {{ $t("facteur_solaire") }}
              </div>
              <b-form-input
                type="number"
                min="0"
                @keydown="(event) => onKeyDown(event)"
                @paste="
                  (event) =>
                    onPaste(
                      event,
                      'fenetre_vitrage_completData.facteur_solaire'
                    )
                "
                v-model="fenetre_vitrage_completData.facteur_solaire"
                :placeholder="$t('facteur_solaire')"
                class="form-input custom-placeholder custom-input"
              >
              </b-form-input>
            </div>
          </form>
          <div v-if="show" class="d-flex justify-content-end mt-4">
      <b-button
        type="submit"
        @click="goToStep"
        class="button-suivant-style m-3"
      >
        <template v-if="isLoading">
          <div class="loading ml-2">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        </template>
       
        <template >
          {{ $t("CONFIRMER") }}
        </template>
      </b-button>
    </div>
        </div>
      </div>
   
    </div>
   
    <div v-if="!show" class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        {{ $t("NEXT") }}
        <div class="loading ml-2" v-if="isLoading">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <Snackbar ref="snackbar" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import domain from "@/environment";
import axios from "axios";
import Snackbar from "@/components/ui/errorSnackbar.vue";
import {
  handlePaste,
  preventExceedingLengthFloat,
  preventExceedingLength,
} from "@/utils/inputUtils";
export default {
  components: {
    Snackbar,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    show: {
      type: Boolean,
      default: false,
      required: false,
    },
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCompleted: false,
      errorMessage: "",
      isLoading: false,
      selected: "A",

      fenetre_vitrage_completData: null,
      fenetre_vitrage_complet: false,

      norme: null,

      type_fenetreOptions: [
        {
          value: "fenêtre(s) de toiture",
          text: this.$t("fenêtre(s) de toiture"),
        },
        {
          value: "double(s) fenêtre(s)",
          text: this.$t("double(s) fenêtre(s)"),
        },

        { value: "porte(s)-fenêtre(s)", text: this.$t("porte(s)-fenêtre(s)") },
        { value: "autre(s) fenêtre(s)", text: this.$t("autre(s) fenêtre(s)") },
      ],
    };
  },
  created() {
    if (this.getdevisMonoFiche && this.getdevisMonoFiche.data) {
      this.fenetre_vitrage_completData =
        this.initializefenetre_vitrage_complet();
      if (this.fenetre_vitrage_completData.id !== null) {
        this.isCompleted = true;
      }
      if (this.fenetre_vitrage_completData.necessite_pare_vapeur === 1) {
        this.selected = "A";
      } else {
        this.selected = "B";
      }
    }
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
    }),
    ...mapGetters(["getdevisMonoFiche"]),
  },

  watch: {
    getdevisMonoFiche: {
      handler(newDevis) {
        if (newDevis && newDevis.data) {
          this.fenetre_vitrage_completData =
            this.initializefenetre_vitrage_complet();
          this.fenetre_vitrage_complet =
            newDevis.data?.devis.fenetre_vitrage_complet !== null;
          if (this.fenetre_vitrage_complet) {
            this.isCompleted = true;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["setDevisMonoFiche"]),

    onKeyDown(event) {
      preventExceedingLengthFloat(event);
    },
    onKeyDownInt(event) {
      preventExceedingLength(event);
    },
    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
    async postIsolationCombleToiture(payload) {
      try {
        const response = await axios.post(
          domain + `/fenetre_vitrage_complet`,
          payload,
          {
            headers: {
              Authorization: `Bearer ` + this.token,
            },
          }
        );
        return response.data.data;
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.errorMessage = error.response.data.message;
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          this.errorMessage = error.response.data.errors.join(", ");
        } else {
          this.errorMessage = "An unknown error occurred.";
        }
        this.$refs.snackbar.showSnackbar(this.errorMessage);
        return null;
      }
    },
    async putIsolationCombleToiture(payload) {
      try {
        const response = await axios.put(
          domain + `/fenetre_vitrage_complet/${payload.id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ` + this.token,
            },
          }
        );

        return response.data.data;
      } catch (error) {
        throw new Error("Failed to post fenetre vitrage complet.");
      }
    },
    initializefenetre_vitrage_complet() {
      const fenetre_vitrage_complet =
        this.getdevisMonoFiche?.data?.devis.fenetre_vitrage_complet || {};

      return {
        id: fenetre_vitrage_complet.id,
        surface_totale: fenetre_vitrage_complet.surface_totale || "",
        facteur_solaire: fenetre_vitrage_complet.facteur_solaire || "",
        coefficient_transmission:
          fenetre_vitrage_complet.coefficient_transmission || "",
        nombre_fenetres: fenetre_vitrage_complet.nombre_fenetres || "",
        type_fenetre: fenetre_vitrage_complet.type_fenetre || "",
      };
    },
    async isolationAdd() {
      const response = await this.postIsolationCombleToiture(
        this.fenetre_vitrage_completData
      );

      if (response.id !== null) {
        this.getdevisMonoFiche.data.devis.fenetre_vitrage_complet_id =
          response.id;
        this.getdevisMonoFiche.data.devis.fenetre_vitrage_complet = {
          id: this.getdevisMonoFiche.data.devis.fenetre_vitrage_complet_id,
          nombre_fenetres: this.fenetre_vitrage_completData.nombre_fenetres,
          coefficient_transmission:
            this.fenetre_vitrage_completData.coefficient_transmission,
          surface_totale: this.fenetre_vitrage_completData.surface_totale,
          facteur_solaire: this.fenetre_vitrage_completData.facteur_solaire,
          type_fenetre: this.fenetre_vitrage_completData.type_fenetre,
        };
        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return response;
      } else {
        this.isLoading = false;

        return;
      }
    },

    async fenetre_vitrage_completAdd() {
      if (this.fenetre_vitrage_complet) {
        const chauffe = {
          ...this.fenetre_vitrage_completData,
          id: this.getdevisMonoFiche.data.devis.fenetre_vitrage_complet.id,
        };

        const reponse = await this.putIsolationCombleToiture(chauffe);
        this.getdevisMonoFiche.data.devis.fenetre_vitrage_complet = {
          id: this.getdevisMonoFiche.data.devis.fenetre_vitrage_complet.id,
          nombre_fenetres: this.fenetre_vitrage_completData.nombre_fenetres,
          surface_totale: this.fenetre_vitrage_completData.surface_totale,
          coefficient_transmission:
            this.fenetre_vitrage_completData.coefficient_transmission,
          facteur_solaire: this.fenetre_vitrage_completData.facteur_solaire,
          type_fenetre: this.fenetre_vitrage_completData.type_fenetre,
        };

        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return reponse;
      }
      await this.isolationAdd();
    },
    async goToStep() {
      this.isLoading = true;
      await this.fenetre_vitrage_completAdd();
      if (this.errorMessage !== "") {
        this.isLoading = false;

        this.$refs.snackbar.showSnackbar("Toutes les données sont requises.");
        this.isCompleted = false;
        return;
      }
      this.isLoading = false;
      this.isCompleted = true;
    },
    async goToNextStep() {
      this.isLoading = true;
      if (this.fenetre_vitrage_completData.nombre_fenetres == "") {
        this.isLoading = false;
        this.$emit("changeStep", 6, true);
      }

      await this.fenetre_vitrage_completAdd();
      if (this.errorMessage !== "") {
        this.isLoading = false;

        return;
      }
      this.isLoading = false;
      this.$emit("changeStep", 6, true);
    },

    goToPrevStep() {
      this.$emit("changeStep", 4);
    },
  },
};
</script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
