<template>
  <div>
    <div>
      <div class="row my-4 mx-1 justify-content-center">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <div v-else class="col-lg-8 col-sm-12 col-md-10 borderPointier">
          <div class="background-white">
            <div class="title">
              <div class="position">{{ $t("conditions_technique") }}</div>
            </div>
          </div>
          <form @submit.prevent="pompe_chaleur_air_airAdd" class="my-3">
<!--             
            <b-form-group :label="$t('TYPE_LOGEMENT')">
              <b-form-radio-group v-model="Type_logement" :options="logementOptions" name="Type_logement" />
         
            </b-form-group> -->
            <div  class="fieldset  mb-2" >
              <div class="legend mb-2">
                {{ $t("Coeffcient_performance") }}
              </div>
              <b-form-input
              type="number"
              min="0"
              @paste="(event) => onPaste(event, 'pompe_chaleur_air_airData.coefficient_performance_saisonnier')"
                 @keydown="(event) => onKeyDown(event)"
              v-model="pompe_chaleur_air_airData.coefficient_performance_saisonnier"
              :placeholder="$t('Coeffcient')"
              class="form-input custom-placeholder custom-input"
      
            >
            </b-form-input>
            </div>
            <div  class="fieldset mb-2" >
              <div class="legend mb-2">
                {{ $t("Puissance") }}
              </div>
              <b-form-input
              type="number"
              min="0"
              @paste="(event) => onPaste(event, 'pompe_chaleur_air_airData.puissance_nominale')"
                @keydown="(event) => onKeyDown(event)"
              v-model="pompe_chaleur_air_airData.puissance_nominale"
          
              :placeholder="$t('Puissance')"
              class="form-input custom-placeholder custom-input"
      
            >
            </b-form-input>
            </div>
            <div  class="fieldset mb-2" >
              <div class="legend mb-2">
                {{ $t("SURFACE") }}
              </div>
              <b-form-input
              type="number"
              min="0"
              @paste="(event) => onPaste(event, 'pompe_chaleur_air_airData.surface_chauffee')"
                @keydown="(event) => onKeyDown(event)"
              v-model="pompe_chaleur_air_airData.surface_chauffee"
              :placeholder="$t('SURFACE')"
              
              class="form-input custom-placeholder custom-input"
      
            >
            </b-form-input>
            </div>
          </form>
          <div v-if="show && (this.getdevisMonoFiche.data.devis.type_devis === 'BAR-TH-174' || this.getdevisMonoFiche.data.devis.type_devis === 'BAR-TH-175'||this.getdevisMonoFiche.data.devis.type_devis === 'Type Complexe')" class="d-flex justify-content-end mt-4">
  <b-button
    type="submit"
    @click="goTotStep"
    class="button-suivant-style m-3"
  >
    <template v-if="isLoading">
      <div class="loading ml-2">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </div>
    </template>

    <template v-else>
      {{ $t("CONFIRMER") }}
    </template>
  </b-button>
</div>
        </div>
      </div>
 
    </div>
    <div v-if="!show || (this.getdevisMonoFiche.data.devis.type_devis !== 'BAR-TH-174' || this.getdevisMonoFiche.data.devis.type_devis !== 'BAR-TH-175'||this.getdevisMonoFiche.data.devis.type_devis !== 'Type Complexe')" class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        {{ $t("NEXT") }}
        <div class="loading ml-2" v-if="isLoading">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <Snackbar ref="snackbar" />
  </div>
</template>

<script>
import { mapActions, mapGetters,mapState } from "vuex";
import domain from "@/environment";
import axios from "axios";
import Snackbar from '@/components/ui/errorSnackbar.vue';
import { handlePaste, preventExceedingLengthFloat } from '@/utils/inputUtils'; 

export default {
  components: {
    Snackbar,
  },
  props: {
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      isCompleted: false,
      errorMessage:'',
      isLoading:false,
      pompe_chaleur_air_airData: null,
      pompe_chaleur_air_air: false,
      
      logementOptions: [
        { text:  this.$t("maison_individuelle"), value: "Maison individuelle" },
        { text:  this.$t("Appartement"), value: "Appartement" },
      ],
   
    };
  },
  created() {
  if (this.getdevisMonoFiche && this.getdevisMonoFiche.data) {
    this.pompe_chaleur_air_airData = this.initializepompe_chaleur_air_air();
  
  }
},
  computed: {
  ...mapState({
    token: (state) => state.token,
  }),
  ...mapGetters(["getdevisMonoFiche"]),

},

  watch: {
    getdevisMonoFiche: {
      handler(newDevis) {
        if (newDevis && newDevis.data) {
          this.pompe_chaleur_air_airData = this.initializepompe_chaleur_air_air();
          this.pompe_chaleur_air_air = newDevis.data?.devis.pompe_chaleur_air_air !== null;
      
          if (this.pompe_chaleur_air_air) {
            this.isCompleted = true;
          }
        }
      },
      deep: true,
      immediate: true,
     
  }
  },
  methods: {
    ...mapActions(["setDevisMonoFiche"]),
    onKeyDown(event) {
      preventExceedingLengthFloat(event);
    },
    
    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
 
      async postPompChaleurAir(payload) {
     
        try {
          const response = await axios.post(
            domain + `/pompe_chaleur_air_air`,
            payload,
            {
              headers: {
                Authorization: `Bearer ` + this.token,
              },
            }
          );
          
          return response.data.data
        } catch (error) {
      
          if (error.response && error.response.data && error.response.data.message) {
      this.errorMessage = error.response.data.message;
    } else if (error.response && error.response.data && error.response.data.errors) {
      this.errorMessage = error.response.data.errors.join(', ');
    } else {
      this.errorMessage = "An unknown error occurred.";
    }
    this.$refs.snackbar.showSnackbar(this.errorMessage);
    return null;
        }
    },
    
    async putPompChaleurAir(payload) {
     
     try {
       const response = await axios.put(
         domain + `/pompe_chaleur_air_air/${payload.id}`,
         payload,
         {
           headers: {
             Authorization: `Bearer ` + this.token,
           },
         }
       );
      
return response.data.data
     } catch (error) {
   
      if (error.response && error.response.data && error.response.data.message) {
      this.errorMessage = error.response.data.message;
    } else if (error.response && error.response.data && error.response.data.errors) {
      this.errorMessage = error.response.data.errors.join(', ');
    } else {
      this.errorMessage = "An unknown error occurred.";
    }
    this.$refs.snackbar.showSnackbar(this.errorMessage);
    return null;
     }
 },
    initializepompe_chaleur_air_air() {
      const pompe_chaleur_air_air = this.getdevisMonoFiche?.data?.devis.pompe_chaleur_air_air || {};
     
      return {
        id: pompe_chaleur_air_air.id,
        puissance_nominale: pompe_chaleur_air_air.puissance_nominale || "",
      
        coefficient_performance_saisonnier:pompe_chaleur_air_air.coefficient_performance_saisonnier||"",
        surface_chauffee: pompe_chaleur_air_air.surface_chauffee || "",

      };
    },
    async isolationAdd() {
     
  
        const response = await this.postPompChaleurAir(this.pompe_chaleur_air_airData);
    
      if(response.id!==null){
      this.getdevisMonoFiche.data.devis.pompe_chaleur_air_air_id = response.id;
      
      this.getdevisMonoFiche.data.devis.pompe_chaleur_air_air = {
        id: this.getdevisMonoFiche.data.devis.pompe_chaleur_air_air_id,
        surface_chauffee: this.pompe_chaleur_air_airData.surface_chauffee,
        coefficient_performance_saisonnier: this.pompe_chaleur_air_airData.coefficient_performance_saisonnier,
        puissance_nominale: this.pompe_chaleur_air_airData.puissance_nominale,
      };
      
      this.setDevisMonoFiche(this.getdevisMonoFiche);
      return response;
    }else{
         
         this.isLoading=false;
 
         return
       }
    },

    async pompe_chaleur_air_airAdd() {
      if (this.pompe_chaleur_air_air) {

        const chauffe = {
          ...this.pompe_chaleur_air_airData,
          id: this.getdevisMonoFiche.data.devis.pompe_chaleur_air_air.id,
        };
        
        const reponse = await this.putPompChaleurAir(chauffe);
        this.getdevisMonoFiche.data.devis.pompe_chaleur_air_air = {
          id: this.getdevisMonoFiche.data.devis.pompe_chaleur_air_air.id,
          surface_chauffee: this.pompe_chaleur_air_airData.surface_chauffee,
          puissance_nominale: this.pompe_chaleur_air_airData.puissance_nominale,
          coefficient_performance_saisonnier:this.pompe_chaleur_air_airData.coefficient_performance_saisonnier,
        };

        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return reponse;
      }
      await this.isolationAdd();
     
    },
    async goTotStep() {
      this.isLoading=true;
     
      await this.pompe_chaleur_air_airAdd();
      if(this.errorMessage!==''){
     
     this.isLoading=false;
     this.$refs.snackbar.showSnackbar("Toutes les données sont requises.");
     this.isCompleted = false;
     return
   }
      this.isLoading=false;
      this.isCompleted = true;

    },
    async goToNextStep() {
      this.isLoading=true;
     
      await this.pompe_chaleur_air_airAdd();
      if(this.errorMessage!==''){
     
     this.isLoading=false;
     this.$refs.snackbar.showSnackbar("Toutes les données sont requises.");

     return
   }
      this.isLoading=false;
      this.$emit("changeStep", 6, true);
    },
    goToPrevStep() {
      this.$emit("changeStep", 4);
    },
  },
};
</script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
