var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row my-4 mx-1 justify-content-center"},[(_vm.progress)?_c('v-container',{staticClass:"loding",attrs:{"type":"image"}},[_c('v-skeleton-loader',{attrs:{"type":"image"}})],1):_c('div',{staticClass:"col-lg-8 col-sm-12 col-md-10 borderPointier"},[_c('div',{staticClass:"background-white"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"position"},[_vm._v(_vm._s(_vm.$t("ADRESSE_TRAVAUX")))])])]),_c('form',{staticClass:"my-4"},[_c('div',{staticClass:"my-3"},[_c('b-form-checkbox',{staticClass:"custom-input",attrs:{"label":_vm.$t('QUESTION_ADRESSE')},on:{"change":_vm.handleCheckboxChange},model:{value:(_vm.isSameTravauxAddress),callback:function ($$v) {_vm.isSameTravauxAddress=$$v},expression:"isSameTravauxAddress"}},[_vm._v(" "+_vm._s(_vm.$t("MEME_ADRESSE_TRAVAUX"))+" ")])],1),(_vm.isSameTravauxAddress)?_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('TRAVAUX_RUE')}},[_c('b-form-input',{staticClass:"form-input custom-placeholder custom-input",attrs:{"value":_vm.getdevisMonoFiche.data.devis.rue_travaux,"placeholder":_vm.$t('TRAVAUX_RUE'),"readonly":""}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('TRAVAUX_CP')}},[_c('b-form-input',{staticClass:"form-input custom-placeholder custom-input",attrs:{"type":"number","maxlength":"5","max":"99999","min":"0","value":_vm.getdevisMonoFiche.data.devis.cp_travaux,"placeholder":_vm.$t('TRAVAUX_CP'),"readonly":""},on:{"paste":(event) =>
                  _vm.onPaste(event, 'getdevisMonoFiche.data.devis.cp_travaux'),"keydown":(event) => _vm.onKeyDown(event),"input":_vm.validatePostalCode}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('TRAVAUX_VILLE')}},[_c('b-form-input',{staticClass:"form-input custom-placeholder custom-input",attrs:{"value":_vm.getdevisMonoFiche.data.devis.ville_travaux,"placeholder":_vm.$t('TRAVAUX_VILLE'),"readonly":""},on:{"keydown":_vm.preventNumberInput}})],1)],1):_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('TRAVAUX_RUE')}},[_c('b-form-input',{staticClass:"form-input custom-placeholder custom-input",attrs:{"placeholder":_vm.$t('TRAVAUX_RUE'),"state":_vm.validationStateRue},on:{"input":function($event){_vm.validateRue();
                _vm.updateDevis(
                  'rue_travaux',
                  _vm.getdevisMonoFiche.data.devis.rue_travaux
                );}},model:{value:(_vm.getdevisMonoFiche.data.devis.rue_travaux),callback:function ($$v) {_vm.$set(_vm.getdevisMonoFiche.data.devis, "rue_travaux", $$v)},expression:"getdevisMonoFiche.data.devis.rue_travaux"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.validationStateRue}},[_vm._v(" "+_vm._s(_vm.$t("REQUIRED_FIELD"))+" ")])],1),_c('b-form-group',{attrs:{"label":_vm.$t('TRAVAUX_CP')}},[_c('b-form-input',{staticClass:"form-input custom-placeholder custom-input",attrs:{"type":"number","maxlength":"5","max":"99999","required":"","min":"0","placeholder":_vm.$t('TRAVAUX_CP'),"state":_vm.validationState},on:{"paste":(event) =>
                  _vm.onPaste(event, 'getdevisMonoFiche.data.devis.cp_travaux'),"keydown":(event) => _vm.onKeyDown(event),"input":_vm.validatePostalCode},model:{value:(_vm.getdevisMonoFiche.data.devis.cp_travaux),callback:function ($$v) {_vm.$set(_vm.getdevisMonoFiche.data.devis, "cp_travaux", $$v)},expression:"getdevisMonoFiche.data.devis.cp_travaux"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.validationState}},[_vm._v(" "+_vm._s(_vm.cpErrorMessage)+" "+_vm._s(_vm.$t("REQUIRED_FIELD"))+" ")])],1),_c('b-form-group',{attrs:{"label":_vm.$t('TRAVAUX_VILLE')}},[_c('b-form-input',{staticClass:"form-input custom-placeholder custom-input",attrs:{"placeholder":_vm.$t('TRAVAUX_VILLE'),"state":_vm.validationStateville},on:{"keydown":_vm.preventNumberInput,"input":function($event){_vm.validateVille();
                _vm.updateDevis(
                  'ville_travaux',
                  _vm.getdevisMonoFiche.data.devis.ville_travaux
                );}},model:{value:(_vm.getdevisMonoFiche.data.devis.ville_travaux),callback:function ($$v) {_vm.$set(_vm.getdevisMonoFiche.data.devis, "ville_travaux", $$v)},expression:"getdevisMonoFiche.data.devis.ville_travaux"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.validationStateville}},[_vm._v(" "+_vm._s(_vm.$t("REQUIRED_FIELD"))+" ")])],1)],1)])])],1),_c('div',{staticClass:"d-flex justify-content-end mt-4"},[_c('b-button',{staticClass:"button-pre-style m-3",on:{"click":_vm.goToPrevStep}},[_vm._v(" "+_vm._s(_vm.$t("PREVIOUS"))+" ")]),_c('b-button',{staticClass:"button-suivant-style m-3",attrs:{"type":"submit"},on:{"click":_vm.goToNextStep}},[_vm._v(" "+_vm._s(_vm.$t("NEXT"))+" "),(_vm.isLoading)?_c('div',{staticClass:"loading ml-2"},[_c('div',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}})]):_vm._e(),(_vm.isLoadingButton)?_c('div',{staticClass:"loading ml-2"},[_c('div',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}})]):_vm._e()])],1),_c('Snackbar',{ref:"snackbar",staticClass:"mt-5"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }