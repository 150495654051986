<template>
  <div scrollable size="xl" class="detaildevisModale modal-test bg-color">
    <div v-if="getdevisMonoFiche.data?.devis" class="modal-test">
      <div class="">
        <div class="part justify">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
              <img
                class="wImag"
                :src="
                  downloadUrl + getdevisMonoFiche?.data?.devis?.entreprise?.logo
                "
                alt="Entreprise Logo"
              />
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="block">
                <div class="d-flex">
                  <b> {{ getdevisMonoFiche.data?.devis?.entreprise?.lib }}</b>
                </div>
                <div class="d-flex">
                  <span>
                    {{ getdevisMonoFiche.data?.devis?.entreprise?.rue }},{{
                      getdevisMonoFiche.data?.devis?.entreprise?.cp
                    }},
                    {{ getdevisMonoFiche.data?.devis?.entreprise?.ville }}</span
                  >
                </div>
                <div class="d-flex">
                  <span class="">{{ $t("N° TVA") }}:</span>
                  <span>
                    {{
                      getdevisMonoFiche.data?.devis?.entreprise?.num_tva
                    }}</span
                  >
                </div>
                <div class="d-flex">
                  <span class="">{{ $t("N°Téléphone") }}:</span>
                  <span>
                    {{
                      getdevisMonoFiche.data?.devis?.entreprise?.phone
                        ?.formated_phone
                    }}</span
                  >
                </div>
              </div>
            </div>

            <div class="col-lg-5 col-md-5 col-sm-12">
              <ul>
                <div>
                  <span
                    class="info"
                    v-if="
                      getdevisMonoFiche.data?.devis?.client?.type !==
                      'type.professionnel'
                    "
                  >
                    <b>
                      {{ getdevisMonoFiche.data?.devis?.client?.nom }}
                      {{ getdevisMonoFiche.data?.devis?.client?.prenom }}</b
                    >
                  </span>
                  <span class="info" v-else>
                    <b> {{ getdevisMonoFiche.data?.devis?.client?.company }}</b>
                  </span>
                </div>

                <span class="info">
                  {{ getdevisMonoFiche.data?.devis?.client?.rue }}
                </span>
                <span class="info">
                  {{ getdevisMonoFiche.data?.devis?.client?.cp }}
                  {{ getdevisMonoFiche.data?.devis?.client?.ville }}
                </span>
                <div>
                  <span class="entrepriseinfo">{{ $t("TEL") }} : </span>
                  <span>
                    {{ getdevisMonoFiche.data.devis?.client?.phone.prefix }}
                    {{
                      getdevisMonoFiche.data.devis?.client?.phone.phone_number
                    }}
                  </span>
                </div>
                <div>
                  <span class="entrepriseinfo">{{ $t("EMAIL") }} : </span>
                  <span>
                    {{ getdevisMonoFiche.data?.devis?.client?.email }}
                  </span>
                </div>
                <div>
                  <span class="entrepriseinfo">{{ $t("ZONE") }} : </span>
                  <span
                    >{{ getdevisMonoFiche.data?.devis?.client?.zone }}
                  </span>
                </div>
                <div>
                  <span class="entrepriseinfo">{{ $t("PRECARITE") }} : </span>
                  <span
                    >{{ getdevisMonoFiche.data?.devis?.client?.precarite }}
                  </span>
                </div>
                <div>
                  <span class="entrepriseinfo"
                    >{{ $t("Type de chauffage") }} :
                  </span>
                  <span>
                    {{
                      getdevisMonoFiche.data?.devis?.logement?.type_chauffage
                    }}
                  </span>
                </div>
                <div>
                  <span class="entrepriseinfo"
                    >{{ $t("Type de logement") }} :
                  </span>

                  <span>
                    {{ getdevisMonoFiche.data?.devis?.logement?.type }}
                  </span>
                  <span
                    v-if="
                      getdevisMonoFiche.data?.devis?.logement?.ancienter === 1
                    "
                    >/Logement neuf
                  </span>

                  <span
                    v-if="
                      getdevisMonoFiche.data?.devis?.logement?.ancienter === 2
                    "
                    >/Moins de 2 ans
                  </span>
                  <span
                    v-if="
                      getdevisMonoFiche.data?.devis?.logement?.ancienter === 3
                    "
                    >/Plus de 2 ans et moins de 15 ans
                  </span>
                  <span
                    v-if="
                      getdevisMonoFiche.data?.devis?.logement?.ancienter === 15
                    "
                    >/Plus de 15 ans
                  </span>
                  <span
                    >/{{
                      getdevisMonoFiche.data?.devis?.logement?.surface
                    }}
                    m²</span
                  >
                </div>
                <div>
                  <span> parcelle cadastrale :</span>
                  <span>{{
                    getdevisMonoFiche.data.devis?.logement?.parcelle_cadastrale
                  }}</span>
                </div>

                <div class="d-flex">
                  <b class="">{{ $t("Adresse des travaux") }}: </b>
                  <span>
                    {{ getdevisMonoFiche.data?.devis?.rue_travaux }}
                    {{ getdevisMonoFiche.data?.devis?.ville_travaux }}
                    {{ getdevisMonoFiche.data?.devis?.cp_travaux }}
                  </span>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <h5 class="my-3">
          {{ $t("DEVIS") }}: {{ getdevisMonoFiche.data?.devis?.numerodevis }}
        </h5>
        <div class="d-flex">
          <span class="">{{ $t("DATE_VISITE_TECHNIQUE") }}:</span>
          <span>
            {{
              formatDate(getdevisMonoFiche.data?.devis?.visite_technique)
            }}</span
          >
        </div>
        <div class="d-flex">
          <span class="">{{ $t("DATE_START_DEVIS") }}:</span>
          <span>
            {{ formatDate(getdevisMonoFiche.data?.devis?.debut_devis) }}
          </span>
        </div>
        <div class="d-flex">
          <span class="">{{ $t("DATE_END_DEVIS") }}:</span>
          <span>
            {{ formatDate(getdevisMonoFiche.data?.devis?.fin_devis) }}
          </span>
        </div>
       
      </div>

      <div class="part bg-color-gray">
        <div>
          <div class="part">
           
            <div
              class="part"
              v-for="(group, index) in getdevisMonoFiche.data
                .groupeLigneDocument"
              :key="index"
            > 
              <div
                style="overflow-x: auto"
                v-if="group.products && group.products.length > 0"
              >
                <div class="mt-3" v-if="group.type_group === 'BAR-TH-174'">
                  <div class="">
                    <div class="piece">
                      BAR-TH-174 : Rénovation d’ampleur d’une maison
                      individuelle
                    </div>
                    <div class="mx-4 mt-2">
                      Rénovation thermique d’ampleur d’une maison individuelle
                      existante.
                      <div
                        v-for="(chantierItem, index) in group.products"
                        :key="index"
                      >
                        <div>
                          <div v-if="chantierItem.type_ligne === 'Product'">
                            <div>
                              Marque :
                              {{ chantierItem.fabricant }}
                            </div>
                            <div>
                              Réf :
                              {{ chantierItem.ref }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="my-2 mx-4">
          <div
            class=""
            v-if="
              group?.sous_traitant !==
              null
            "
          >
            Traveaux sous-traités auprès de l'entreprise
            <b>
              {{
                group?.sous_traitant
                  ?.name
              }}</b
            >
          </div>
          <div v-else class="">
            Matériel(s) fourni(s) et mis en place par notre société
            <b> {{ getdevisMonoFiche.data.devis.entreprise?.lib }}</b>
          </div>
          <div
            class=""
            v-if="
              group.sous_traitant !==
              null
            "
          >
            représentée par
            {{
             group.sous_traitant?.name
            }}
            , SIRET
            <b>
              {{
              group.sous_traitant
                  ?.num_siret
              }}
              ,</b
            >
            Certificat rge
          </div>
          <div v-else class="">
            représentée par
            {{ getdevisMonoFiche.data.devis.entreprise?.lib }} , SIRET
            <b>
              {{ getdevisMonoFiche.data.devis.entreprise?.siret }}
              ,</b
            >
            Certificat rge
          </div>
          <div class="">
            Numéro
            <b> {{  group.selected_certificat_rge?.num }} </b>
            attribué le
            <b>
              {{
                formatDate(
                  group.selected_certificat_rge
                    ?.date_attribution
                )
              }}
            </b>
            valable jusqu'au
            <b>{{
              formatDate(
                group.selected_certificat_rge?.date_expiration
              )
            }}</b
            >, Assurance
          </div>
          <div class="">
            civile
            <b>
              N°
              {{ getdevisMonoFiche.data?.devis?.entreprise?.num_assurance }}</b
            >
          </div>
        </div>
        <div class="mx-4">
                  Cumac : <b>{{ group?.cumac }} kWh</b>
                </div>
                <div class="mx-4">
                  Prime CEE Coup de Pouce :
                  <b>{{group.montant_cee }} € </b>
                </div>
                </div>
                <div class="mt-3" v-if="group.type_group === 'BAR-EN-103'">
                  <div class="">
                    <div class="piece">
                      BAR-EN-103 : Isolation d’un plancher
                    </div>
                    <div class="mx-4 mt-2">
                      Mise en place d’un procédé d’isolation sur/sous un
                      plancher bas situé entre un volume chauffé et un sous-sol
                      non chauffé, un vide sanitaire ou un passage ouvert. Un
                      procédé d'isolation est constitué de l'association d'un
                      matériau isolant et de dispositifs de fixation et de
                      protection (tels que des revêtements, parements, membranes
                      continues si nécessaire) contre des dégradations liées à
                      son exposition aux environnements extérieurs et intérieurs
                      (telles que le rayonnement solaire, le vent, la pluie, la
                      neige, les chocs, l'humidité, le feu).

                      <div>Caractéristiques de l'isolant posé:</div>

                      <div>
                        Surface d’isolant posé:
                        <b
                          >{{
                            getdevisMonoFiche.data.devis?.isolation_plancher
                              ?.Surface_isolant
                          }}
                          m²</b
                        >
                      </div>
                      <div>
                        Résistance thermique:
                        <b
                          >{{
                            getdevisMonoFiche.data.devis?.isolation_plancher
                              ?.resistance_thermique
                          }}
                          m².K/W</b
                        >
                      </div>
                      <div>
                        Épaisseur:
                        <b
                          >{{
                            getdevisMonoFiche.data.devis?.isolation_plancher
                              ?.epaisseur
                          }}
                          mm</b
                        >
                      </div>
                      <div>
                        <div>
                          Type d’isolant :

                          <b>{{
                            getdevisMonoFiche.data.devis?.isolation_plancher
                              ?.type_isolant
                          }}</b>
                        </div>
                        <div>
                          La résistance thermique est évaluée selon la norme
                          <b>{{
                            getdevisMonoFiche.data?.devis?.isolation_plancher
                              ?.norme
                          }}</b>
                        </div>
                      </div>
                      <div
                        v-if="
                          getdevisMonoFiche.data?.devis?.isolation_plancher
                            ?.necessite_pare_vapeur === 1
                        "
                      >
                        L’isolation thermique réalisée a nécessité la mise en
                        place d’un pare-vapeur ou tout autre dispositif
                        permettant d'atteindre un résultat équivalent
                      </div>
                      <div
                        v-for="(chantierItem, index) in group.products"
                        :key="index"
                      >
                        <div>
                          <div v-if="chantierItem.type_ligne === 'Product'">
                            <div>
                              Marque :
                              {{ chantierItem.fabricant }}
                            </div>
                            <div>
                              Réf :
                              {{ chantierItem.ref }}
                            </div>
                          </div>
                        </div>
                      </div>
                     </div>
                     <div class="my-2 mx-4">
          <div
            class=""
            v-if="
              group?.sous_traitant !==
              null
            "
          >
            Traveaux sous-traités auprès de l'entreprise
            <b>
              {{
                group?.sous_traitant
                  ?.name
              }}</b
            >
          </div>
          <div v-else class="">
            Matériel(s) fourni(s) et mis en place par notre société
            <b> {{ getdevisMonoFiche.data.devis.entreprise?.lib }}</b>
          </div>
          <div
            class=""
            v-if="
              group.sous_traitant !==
              null
            "
          >
            représentée par
            {{
             group.sous_traitant?.name
            }}
            , SIRET
            <b>
              {{
              group.sous_traitant
                  ?.num_siret
              }}
              ,</b
            >
            Certificat rge
          </div>
          <div v-else class="">
            représentée par
            {{ getdevisMonoFiche.data.devis.entreprise?.lib }} , SIRET
            <b>
              {{ getdevisMonoFiche.data.devis.entreprise?.siret }}
              ,</b
            >
            Certificat rge
          </div>
          <div class="">
            Numéro
            <b> {{  group.selected_certificat_rge?.num }} </b>
            attribué le
            <b>
              {{
                formatDate(
                  group.selected_certificat_rge
                    ?.date_attribution
                )
              }}
            </b>
            valable jusqu'au
            <b>{{
              formatDate(
                group.selected_certificat_rge?.date_expiration
              )
            }}</b
            >, Assurance
          </div>
          <div class="">
            civile
            <b>
              N°
              {{ getdevisMonoFiche.data?.devis?.entreprise?.num_assurance }}</b
            >
          </div>
        </div>
                  </div>
                  <div class="mx-4">
                  Cumac : <b>{{ group?.cumac }} kWh</b>
                </div>
                <div class="mx-4">
                  Prime CEE Coup de Pouce :
                  <b>{{group.montant_cee }} € </b>
                </div>
                </div>
                <div class="mt-3" v-if="group.type_group === 'BAR-EN-102'">
                  <div>
                    <div class="">
                      <div class="piece">BAR-EN-102 : Isolation des murs</div>
                      <div class="mx-4 mt-2">
                        Mise en place d’un procédé d’isolation (complexe ou sur
                        ossature) sur mur(s) en façade ou en pignon. Un procédé
                        d'isolation est constitué de l'association d'un matériau
                        isolant et de dispositifs de fixation et de protection
                        (tels que des revêtements, parements, membranes
                        continues si nécessaire) contre des dégradations liées à
                        son exposition aux environnements extérieurs et
                        intérieurs (telles que le rayonnement solaire, le vent,
                        la pluie, la neige, les chocs, l'humidité, le feu).

                        <div>Caractéristiques de l'isolant posé:</div>

                        <div>
                          Surface d’isolant posé:
                          <b
                            >{{
                              getdevisMonoFiche.data.devis?.isolation_murs
                                ?.Surface_isolant
                            }}
                            m²</b
                          >
                        </div>
                        <div>
                          Résistance thermique:
                          <b
                            >{{
                              getdevisMonoFiche.data.devis?.isolation_murs
                                ?.resistance_thermique
                            }}
                            m².K/W</b
                          >
                        </div>
                        <div>
                          Épaisseur:
                          <b
                            >{{
                              getdevisMonoFiche.data.devis?.isolation_murs
                                ?.epaisseur
                            }}
                            mm</b
                          >
                        </div>
                        <div>
                          <div>
                            Type d’isolant :

                            <b>{{
                              getdevisMonoFiche.data.devis?.isolation_murs
                                ?.type_isolant
                            }}</b>
                          </div>
                          <div>
                            La résistance thermique est évaluée selon la norme
                            <b>{{
                              getdevisMonoFiche.data?.devis?.isolation_murs
                                ?.norme
                            }}</b>
                          </div>
                        </div>
                        <div
                          v-for="(chantierItem, index) in group.products"
                          :key="index"
                        >
                          <div>
                            <div v-if="chantierItem.type_ligne === 'Product'">
                              <div>
                                Marque :
                                {{ chantierItem.fabricant }}
                              </div>
                              <div>
                                Réf :
                                {{ chantierItem.ref }}
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                    </div>
                  </div>
                  <div class="mx-4">
                  Cumac : <b>{{ group?.cumac }} kWh</b>
                </div>
                <div class="mx-4">
                  Prime CEE Coup de Pouce :
                  <b>{{group.montant_cee }} € </b>
                </div>
                </div>
                <div class="mt-3" v-if="group.type_group === 'BAR-EN-101'">
                  <div class="">
                    <div class="piece">
                      BAR-EN-101 : Isolation de combles ou de toiture
                    </div>
                    <div class="mx-4 mt-2">
                      Mise en place d’un procédé d’isolation thermique en comble
                      perdu ou en rampant de toiture. Un procédé d'isolation est
                      constitué de l'association d'un matériau isolant et de
                      dispositifs de fixation et de protection (tels que des
                      revêtements, parements, membranes continues si nécessaire)
                      contre des dégradations liées à son exposition aux
                      environnements extérieurs et intérieurs (telles que le
                      rayonnement solaire, le vent, la pluie, la neige, les
                      chocs, l'humidité, le feu).
                      <div>
                        Type de pose :
                        <b>
                          {{
                            getdevisMonoFiche.data.devis
                              ?.isolation_combles_toiture?.type_pose
                          }}</b
                        >
                      </div>
                      <div>
                        Caractéristiques de l'isolant posé:
                        <div>
                          <div>
                            Type d’isolant :

                            <b>{{
                              getdevisMonoFiche.data.devis
                                ?.isolation_combles_toiture?.type_isolant
                            }}</b>
                          </div>
                          <div>
                            La résistance thermique est évaluée selon la norme
                            <b>{{
                              getdevisMonoFiche.data?.devis
                                ?.isolation_combles_toiture?.norme
                            }}</b>
                          </div>
                        </div>
                      </div>

                      <div>
                        Surface d’isolant posé:
                        <b
                          >{{
                            getdevisMonoFiche.data.devis
                              ?.isolation_combles_toiture?.surface_isolant
                          }}
                          m²</b
                        >
                      </div>
                      <div>
                        Résistance thermique:
                        <b
                          >{{
                            getdevisMonoFiche.data.devis
                              ?.isolation_combles_toiture?.resistance_thermique
                          }}
                          m².K/W</b
                        >
                      </div>
                      <div>
                        Épaisseur:
                        <b
                          >{{
                            getdevisMonoFiche.data.devis
                              ?.isolation_combles_toiture?.epaisseur
                          }}
                          mm</b
                        >
                      </div>
                      <div>
                        <div>
                          Type d’isolant :

                          <b>{{
                            getdevisMonoFiche.data.devis
                              ?.isolation_combles_toiture?.type_isolant
                          }}</b>
                        </div>
                        <div>
                          La résistance thermique est évaluée selon la norme
                          <b>{{
                            getdevisMonoFiche.data?.devis
                              ?.isolation_combles_toiture?.norme
                          }}</b>
                        </div>
                      </div>
                      <div
                        v-if="
                          getdevisMonoFiche.data?.devis
                            ?.isolation_combles_toiture
                            ?.necessite_pare_vapeur === 1
                        "
                      >
                        L’isolation thermique réalisée a nécessité la mise en
                        place d’un pare-vapeur ou tout autre dispositif
                        permettant d'atteindre un résultat équivalen
                      </div>
                      <div
                        v-for="(chantierItem, index) in group.products"
                        :key="index"
                      >
                        <div>
                          <div v-if="chantierItem.type_ligne === 'Product'">
                            <div>
                              Marque :
                              {{ chantierItem.fabricant }}
                            </div>
                            <div>
                              Réf :
                              {{ chantierItem.ref }}
                            </div>
                          </div>
                        </div>
                      </div>
                     </div>
                  </div>
                  <div class="my-2 mx-4">
          <div
            class=""
            v-if="
              group?.sous_traitant !==
              null
            "
          >
            Traveaux sous-traités auprès de l'entreprise
            <b>
              {{
                group?.sous_traitant
                  ?.name
              }}</b
            >
          </div>
          <div v-else class="">
            Matériel(s) fourni(s) et mis en place par notre société
            <b> {{ getdevisMonoFiche.data.devis.entreprise?.lib }}</b>
          </div>
          <div
            class=""
            v-if="
              group.sous_traitant !==
              null
            "
          >
            représentée par
            {{
             group.sous_traitant?.name
            }}
            , SIRET
            <b>
              {{
              group.sous_traitant
                  ?.num_siret
              }}
              ,</b
            >
            Certificat rge
          </div>
          <div v-else class="">
            représentée par
            {{ getdevisMonoFiche.data.devis.entreprise?.lib }} , SIRET
            <b>
              {{ getdevisMonoFiche.data.devis.entreprise?.siret }}
              ,</b
            >
            Certificat rge
          </div>
          <div class="">
            Numéro
            <b> {{  group.selected_certificat_rge?.num }} </b>
            attribué le
            <b>
              {{
                formatDate(
                  group.selected_certificat_rge
                    ?.date_attribution
                )
              }}
            </b>
            valable jusqu'au
            <b>{{
              formatDate(
                group.selected_certificat_rge?.date_expiration
              )
            }}</b
            >, Assurance
          </div>
          <div class="">
            civile
            <b>
              N°
              {{ getdevisMonoFiche.data?.devis?.entreprise?.num_assurance }}</b
            >
          </div>
        </div>
        <div class="mx-4">
                  Cumac : <b>{{ group?.cumac }} kWh</b>
                </div>
                <div class="mx-4">
                  Prime CEE Coup de Pouce :
                  <b>{{group.montant_cee }} € </b>
                </div>
                </div>

                <div class="mt-3" v-if="group.type_group === 'BAR-TH-127'">
                  <div class="">
                    <div class="piece">
                      BAR-TH-127: Ventilation mécanique simple flux
                      hygroréglable (France métropolitaine)
                    </div>
                    <div class="mx-4 mt-2">
                      Mise en place d’un système de ventilation mécanique
                      contrôlée (VMC) simple flux hygroréglable ou d’un système
                      de ventilation mécanique basse pression (VMBP) collectif
                      simple flux hygroréglable. Ces systèmes de ventilation
                      peuvent être de type A ou B.
                      <div>
                        <div>
                          Type d’installation :
                          <b>{{
                            getdevisMonoFiche.data.devis
                              ?.systeme_ventilation_mecanique_controlee
                              ?.type_installation
                          }}</b>
                        </div>
                        <div>
                          Type de ventilation mécanique contrôlée :
                          <b>{{
                            getdevisMonoFiche.data.devis
                              ?.systeme_ventilation_mecanique_controlee
                              ?.type_ventilation
                          }}</b>
                        </div>
                        <div>
                          <div
                            v-if="
                              getdevisMonoFiche.data.devis
                                ?.systeme_ventilation_mecanique_controlee
                                ?.type_installation ===
                              'Installation collective'
                            "
                          >
                            Nombre de logements desservis :
                            <b>
                              {{
                                getdevisMonoFiche.data.devis
                                  ?.systeme_ventilation_mecanique_controlee
                                  ?.nombre_logements
                              }}</b
                            >. Puissance
                            <b>
                              {{
                                getdevisMonoFiche.data.devis
                                  ?.systeme_ventilation_mecanique_controlee
                                  ?.puissance_electrique
                              }}</b
                            >
                          </div>
                          .

                         </div>
                        <div
                          v-for="(chantierItem, index) in group.products"
                          :key="index"
                        >
                          <div>
                            <div v-if="chantierItem.type_ligne === 'Product'">
                              <div>
                                Marque :
                                {{ chantierItem.fabricant }}
                              </div>
                              <div>
                                Réf :
                                {{ chantierItem.ref }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="my-2 mx-4">
          <div
            class=""
            v-if="
              group?.sous_traitant !==
              null
            "
          >
            Traveaux sous-traités auprès de l'entreprise
            <b>
              {{
                group?.sous_traitant
                  ?.name
              }}</b
            >
          </div>
          <div v-else class="">
            Matériel(s) fourni(s) et mis en place par notre société
            <b> {{ getdevisMonoFiche.data.devis.entreprise?.lib }}</b>
          </div>
          <div
            class=""
            v-if="
              group.sous_traitant !==
              null
            "
          >
            représentée par
            {{
             group.sous_traitant?.name
            }}
            , SIRET
            <b>
              {{
              group.sous_traitant
                  ?.num_siret
              }}
              ,</b
            >
            Certificat rge
          </div>
          <div v-else class="">
            représentée par
            {{ getdevisMonoFiche.data.devis.entreprise?.lib }} , SIRET
            <b>
              {{ getdevisMonoFiche.data.devis.entreprise?.siret }}
              ,</b
            >
            Certificat rge
          </div>
          <div class="">
            Numéro
            <b> {{  group.selected_certificat_rge?.num }} </b>
            attribué le
            <b>
              {{
                formatDate(
                  group.selected_certificat_rge
                    ?.date_attribution
                )
              }}
            </b>
            valable jusqu'au
            <b>{{
              formatDate(
                group.selected_certificat_rge?.date_expiration
              )
            }}</b
            >, Assurance
          </div>
          <div class="">
            civile
            <b>
              N°
              {{ getdevisMonoFiche.data?.devis?.entreprise?.num_assurance }}</b
            >
          </div>
        </div>
        <div class="mx-4">
                  Cumac : <b>{{ group?.cumac }} kWh</b>
                </div>
                <div class="mx-4">
                  Prime CEE Coup de Pouce :
                  <b>{{group.montant_cee }} € </b>
                </div>
                </div>
                <div class="mt-3" v-if="group.type_group === 'BAR-TH-148'">
                  <div class="">
                    <div class="piece">
                      BAR-TH-148 : Chauffe-eau thermodynamique à accumulation
                    </div>
                    <div class="mx-4 mt-2">
                      Chauffe-eau thermodynamique à accumulation

                      <div>
                        Caractéristiques du chauffe-eau thermodynamique :

                        <div>
                          Type d’installation :
                          <b>{{
                            getdevisMonoFiche.data.devis
                              ?.chauffe_eau_thermodynamiques?.type_installation
                          }}</b>
                        </div>
                        <div>
                          COP :
                          <b>{{
                            getdevisMonoFiche.data.devis
                              ?.chauffe_eau_thermodynamiques?.cop
                          }}</b>
                        </div>
                        <div
                          v-for="(chantierItem, index) in group.products"
                          :key="index"
                        >
                          <div>
                            <div v-if="chantierItem.type_ligne === 'Product'">
                              <div>
                                Marque :
                                {{ chantierItem.fabricant }}
                              </div>
                              <div>
                                Réf :
                                {{ chantierItem.ref }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="my-2 mx-4">
          <div
            class=""
            v-if="
              group?.sous_traitant !==
              null
            "
          >
            Traveaux sous-traités auprès de l'entreprise
            <b>
              {{
                group?.sous_traitant
                  ?.name
              }}</b
            >
          </div>
          <div v-else class="">
            Matériel(s) fourni(s) et mis en place par notre société
            <b> {{ getdevisMonoFiche.data.devis.entreprise?.lib }}</b>
          </div>
          <div
            class=""
            v-if="
              group.sous_traitant !==
              null
            "
          >
            représentée par
            {{
             group.sous_traitant?.name
            }}
            , SIRET
            <b>
              {{
              group.sous_traitant
                  ?.num_siret
              }}
              ,</b
            >
            Certificat rge
          </div>
          <div v-else class="">
            représentée par
            {{ getdevisMonoFiche.data.devis.entreprise?.lib }} , SIRET
            <b>
              {{ getdevisMonoFiche.data.devis.entreprise?.siret }}
              ,</b
            >
            Certificat rge
          </div>
          <div class="">
            Numéro
            <b> {{  group.selected_certificat_rge?.num }} </b>
            attribué le
            <b>
              {{
                formatDate(
                  group.selected_certificat_rge
                    ?.date_attribution
                )
              }}
            </b>
            valable jusqu'au
            <b>{{
              formatDate(
                group.selected_certificat_rge?.date_expiration
              )
            }}</b
            >, Assurance
          </div>
          <div class="">
            civile
            <b>
              N°
              {{ getdevisMonoFiche.data?.devis?.entreprise?.num_assurance }}</b
            >
          </div>
          <div class="mx-4">
                  Cumac : <b>{{ group?.cumac }} kWh</b>
                </div>
                <div class="mx-4">
                  Prime CEE Coup de Pouce :
                  <b>{{group.montant_cee }} € </b>
                </div>
        </div>
        <div class="mx-4">
                  Cumac : <b>{{ group?.cumac }} kWh</b>
                </div>
                <div class="mx-4">
                  Prime CEE Coup de Pouce :
                  <b>{{group.montant_cee }} € </b>
                </div>
                </div>
                <div class="mt-3" v-if="group.type_group === 'BAR-TH-171'">
                  <div>
                    <div>
                      BAR-TH-171 :
                      <p>Pompe à chaleur de type air/eau</p>
                      <b
                        >{{
                          getdevisMonoFiche.data.devis?.systeme_chauffage
                            ?.denomination_temperature
                        }} </b
                      >temperature.
                    </div>
                    <div>
                      Le coefficient de performance (COP) est de :
                      <b>{{
                        getdevisMonoFiche.data.devis?.systeme_chauffage
                          ?.coefficient_performance
                      }}</b>
                    </div>
                    <div>
                      L'efficacité énergitique saisonnière est de :
                      <b
                        >{{
                          getdevisMonoFiche.data.devis?.systeme_chauffage
                            ?.efficacite
                        }}
                        %</b
                      >
                      calculée selon le règlement (EU)
                    </div>
                    <div>n°813/2013 de la commission du 2 aout 2013</div>
                    <div>
                      La surface chauffée par la PAC est de
                      <b>{{
                        getdevisMonoFiche.data.devis?.systeme_chauffage
                          ?.surface_chauffee
                      }}</b>
                    </div>
                    <div
                      v-for="(chantierItem, index) in group.products"
                      :key="index"
                    >
                      <div>
                        <div v-if="chantierItem.type_ligne === 'Product'">
                          <div>
                            Marque :
                            {{ chantierItem.fabricant }}
                          </div>
                          <div>
                            Réf :
                            {{ chantierItem.ref }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      Mise en place d'un régulateur de classe :
                      <b>{{
                        getdevisMonoFiche.data.devis?.systeme_chauffage
                          ?.regulateur_classe
                      }}</b>
                    </div>
                    <div></div>
                    <ul>
                      <li>
                        Dépose et remplacement d'une chaudière
                        <b>{{
                          getdevisMonoFiche?.data?.devis?.systeme_chauffage
                            ?.equipement_deposee
                        }}</b>
                      </li>
                    </ul>
                  </div>
                  <div class="my-2 mx-4">
          <div
            class=""
            v-if="
              group?.sous_traitant !==
              null
            "
          >
            Traveaux sous-traités auprès de l'entreprise
            <b>
              {{
                group?.sous_traitant
                  ?.name
              }}</b
            >
          </div>
          <div v-else class="">
            Matériel(s) fourni(s) et mis en place par notre société
            <b> {{ getdevisMonoFiche.data.devis.entreprise?.lib }}</b>
          </div>
          <div
            class=""
            v-if="
              group.sous_traitant !==
              null
            "
          >
            représentée par
            {{
             group.sous_traitant?.name
            }}
            , SIRET
            <b>
              {{
              group.sous_traitant
                  ?.num_siret
              }}
              ,</b
            >
            Certificat rge
          </div>
          <div v-else class="">
            représentée par
            {{ getdevisMonoFiche.data.devis.entreprise?.lib }} , SIRET
            <b>
              {{ getdevisMonoFiche.data.devis.entreprise?.siret }}
              ,</b
            >
            Certificat rge
          </div>
          <div class="">
            Numéro
            <b> {{  group.selected_certificat_rge?.num }} </b>
            attribué le
            <b>
              {{
                formatDate(
                  group.selected_certificat_rge
                    ?.date_attribution
                )
              }}
            </b>
            valable jusqu'au
            <b>{{
              formatDate(
                group.selected_certificat_rge?.date_expiration
              )
            }}</b
            >, Assurance
          </div>
          <div class="">
            civile
            <b>
              N°
              {{ getdevisMonoFiche.data?.devis?.entreprise?.num_assurance }}</b
            >
          </div>
          <div class="mx-4">
                  Cumac : <b>{{ group?.cumac }} kWh</b>
                </div>
                <div class="mx-4">
                  Prime CEE Coup de Pouce :
                  <b>{{group.montant_cee }} € </b>
                </div>
        </div>
                </div>
                <div class="mt-3" v-if="group.type_group === 'BAR-EN-104'">
                  <div class="mt-3">
                    <div class="piece">
                      BAR-EN-104 : Fenêtre ou porte-fenêtre complète avec
                      vitrage isolant
                    </div>
                    <div class="mx-4 mt-2">
                      Mise en place d’une fenêtre, fenêtre de toiture ou
                      porte-fenêtre complète avec vitrage isolant, les baies
                      fixes étant permises, pour le remplacement d’une fenêtre,
                      fenêtre de toiture ou porte-fenêtre équipée de simple
                      vitrage avant travaux ou mise en place d’une double
                      fenêtre sur une fenêtre équipée de simple vitrage avant
                      travaux. L’opération inclut le remplacement du dormant
                      existant, sauf dans le cas de l’installation d’une double
                      fenêtre.

                      <div>Caractéristiques :</div>
                      <div>
                        Type de fenêtre:<b
                          >{{
                            getdevisMonoFiche.data.devis
                              ?.fenetre_vitrage_complet?.type_fenetre
                          }}
                        </b>
                      </div>
                      <div>
                        Nombre de
                        {{
                          getdevisMonoFiche.data.devis?.fenetre_vitrage_complet
                            ?.type_fenetre
                        }}:
                        <b
                          >{{
                            getdevisMonoFiche.data.devis
                              ?.fenetre_vitrage_complet?.nombre_fenetres
                          }}
                        </b>
                      </div>
                      <div>
                        Surface totale de
                        {{
                          getdevisMonoFiche.data.devis?.fenetre_vitrage_complet
                            ?.type_fenetre
                        }}:
                        <b
                          >{{
                            getdevisMonoFiche.data.devis
                              ?.fenetre_vitrage_complet?.surface_totale
                          }}
                          m²
                        </b>
                      </div>
                      <div>
                        <div>
                          Coefficient de transmission surfacique Uw:

                          <b
                            >{{
                              getdevisMonoFiche.data.devis
                                ?.fenetre_vitrage_complet
                                ?.coefficient_transmission
                            }}
                            W/m².K</b
                          >
                        </div>
                        <div>
                          Facteur solaire Sw :
                          <b>{{
                            getdevisMonoFiche.data?.devis
                              ?.fenetre_vitrage_complet?.facteur_solaire
                          }}</b>
                        </div>
                      </div>
                      <div
                        v-for="(chantierItem, index) in group.products"
                        :key="index"
                      >
                        <div>
                          <div v-if="chantierItem.type_ligne === 'Product'">
                            <div>
                              Marque :
                              {{ chantierItem.fabricant }}
                            </div>
                            <div>
                              Réf :
                              {{ chantierItem.ref }}
                            </div>
                          </div>
                        </div>
                      </div>
                   </div>
                  </div>
                  <div class="my-2 mx-4">
          <div
            class=""
            v-if="
              group?.sous_traitant !==
              null
            "
          >
            Traveaux sous-traités auprès de l'entreprise
            <b>
              {{
                group?.sous_traitant
                  ?.name
              }}</b
            >
          </div>
          <div v-else class="">
            Matériel(s) fourni(s) et mis en place par notre société
            <b> {{ getdevisMonoFiche.data.devis.entreprise?.lib }}</b>
          </div>
          <div
            class=""
            v-if="
              group.sous_traitant !==
              null
            "
          >
            représentée par
            {{
             group.sous_traitant?.name
            }}
            , SIRET
            <b>
              {{
              group.sous_traitant
                  ?.num_siret
              }}
              ,</b
            >
            Certificat rge
          </div>
          <div v-else class="">
            représentée par
            {{ getdevisMonoFiche.data.devis.entreprise?.lib }} , SIRET
            <b>
              {{ getdevisMonoFiche.data.devis.entreprise?.siret }}
              ,</b
            >
            Certificat rge
          </div>
          <div class="">
            Numéro
            <b> {{  group.selected_certificat_rge?.num }} </b>
            attribué le
            <b>
              {{
                formatDate(
                  group.selected_certificat_rge
                    ?.date_attribution
                )
              }}
            </b>
            valable jusqu'au
            <b>{{
              formatDate(
                group.selected_certificat_rge?.date_expiration
              )
            }}</b
            >, Assurance
          </div>
          <div class="">
            civile
            <b>
              N°
              {{ getdevisMonoFiche.data?.devis?.entreprise?.num_assurance }}</b
            >
          </div>
        </div>
        <div class="mx-4">
                  Cumac : <b>{{ group?.cumac }} kWh</b>
                </div>
                <div class="mx-4">
                  Prime CEE Coup de Pouce :
                  <b>{{group.montant_cee }} € </b>
                </div>
                </div>

                
                <table class="table">
                  <thead>
                    <tr>
                      <th style="border-radius: 5px 0px 0px 0px" scope="col">
                        {{ $t("REF") }}
                      </th>
                      <th>{{ $t("WORDING") }}</th>
                      <th>{{ $t("DESC") }}</th>
                      <th>{{ $t("QTE") }}</th>
                      <th>{{ $t("UNITEU") }}</th>
                      <th>{{ $t("PUHT") }}</th>
                      <th>
                        {{ $t("TOTALHT") }}
                      </th>
                      <th style="border-radius: 0px 5px 0px 0px">
                        {{ $t("VAT") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(product, index) in group.products" :key="index">
                      <td>{{ product.ref ? product.ref : "-" }}</td>
                      <td>
                        <div class="descblock">
                          {{ product.lib }}
                        </div>
                      </td>
                      <td>
                        <div class="descblock">
                          {{ product.desc }}
                        </div>
                      </td>
                      <td>{{ product.quantite }}</td>
                      <td>{{ product.type_unite }}</td>
                      <td>{{ product.unit_price }}</td>
                      <td>{{ product.unit_price * product.quantite }}</td>
                      <td>{{ product.tva }} %</td>
                    </tr>
                  </tbody>
                </table>
              </div>
    
            <div class="flexEndTotale">
            <div class="subtableTotale">
              <table class="completTotale">
              

                <template v-if="getdevisMonoFiche.data?.tva_document">
                  <tr
                 
                  >
                    <td class="theadTotale">TVA ({{ getdevisMonoFiche.data?.tva_document[index]?.tva }}%)</td>
                    <td style="border-radius: 0px 5px 0px 0px">
                      {{ getdevisMonoFiche.data?.tva_document[index]?.tva_montant }}
                    </td>
                  </tr>
                </template>
                <tr>
                  <td class="theadTotale">Prime CEE Coup de Pouce</td>
                  <td>{{ group.montant_cee}} €</td>
                </tr>
           

              
              </table>
            </div>
          </div>
            </div>
          </div>
         
        </div>
        
      </div>
      <div class="flexEndTotale">
            <div class="subtableTotale">
              <table class="completTotale">
                <tr>
                  <td class="theadTotale">
                    {{ $t("TOTAL_HT") }}
                  </td>
                  <td>{{ getdevisMonoFiche.data?.total_HT }} €</td>
                </tr>

                <template v-if="getdevisMonoFiche.data?.tva_document">
                  <tr
                    v-for="tva in getdevisMonoFiche.data?.tva_document"
                    :key="'tva' + tva.id"
                  >
                    <td class="theadTotale">TVA ({{ tva?.tva }}%)</td>
                    <td style="border-radius: 0px 5px 0px 0px">
                      {{ tva?.tva_montant }}
                    </td>
                  </tr>
                </template>
                <!-- <tr>
                  <td class="theadTotale">Prime CEE Coup de Pouce</td>
                  <td>{{ getdevisMonoFiche.data?.devis?.montant_cee }} €</td>
                </tr> -->
                <tr>
                  <td class="theadTotale">
                    {{ $t("Total TTC") }}
                  </td>
                  <td>{{ getdevisMonoFiche.data?.total_TTC }} €</td>
                </tr>

                <tr v-if="getdevisMonoFiche.data?.devis?.total_to_pay">
                  <td class="theadTotale">
                    {{ $t("NET à payer") }}
                  </td>
                  <td>{{ getdevisMonoFiche.data?.devis?.total_to_pay }} €</td>
                </tr>
              </table>
            </div>
          </div>
      <div class="part mt-3">
        <div><b>Termes et conditions CEE</b></div>
        <div v-if="getdevisMonoFiche.data.devis?.mandataire">
          <p>{{ getdevisMonoFiche.data.devis?.mandataire?.paragraphCEE }}</p>
        </div>
        <div v-if="!getdevisMonoFiche.data.devis?.mandataire">
          <p>{{ getdevisMonoFiche.data.devis?.partenaire?.paragraphCEE }}</p>
        </div>
     
        <div></div>
      
        <div>
       
        </div>
      </div>

      <div class="part mt-3">
        <div>
          Mode de paiement :
          <div v-if="moyens_paiement.length === 0">
            Aucun moyen de paiement disponible.
          </div>
          <div v-else>
            <span
              v-for="(paiement, index) in moyens_paiement"
              :key="paiement.id"
            >
              <b v-if="paiement.moyen_paiement === 'Organisme_de_financement'"
                >Organisme de financement</b
              >
              <b v-if="paiement.moyen_paiement === 'cheque_virement_espece'"
                >Chèque virement espèce</b
              >
              <b
                v-if="paiement.moyen_paiement === 'Certificat_Economie_energie'"
                >Certificat d'Économie d'énergie (CEE)</b
              >
              <b v-if="paiement.moyen_paiement === 'Ma_prime_renove'"
                >Ma prime rénove (MPR)</b
              >
              <b v-if="paiement.moyen_paiement === 'Autre'">Autre</b>
              <span v-if="index < moyens_paiement.length - 1"> / </span>
            </span>
          </div>
        </div>

        <h3
          v-if="
            +getdevisMonoFiche.data?.devis?.montant_maprimrenov &&
            getdevisMonoFiche.data?.devis?.with_aide
          "
        >
          <span class="part-title"> {{ $t("MA_PRIME_RENOV") }}: </span>

          <span>{{
            getdevisMonoFiche.data?.devis?.montant_maprimrenov | financial
          }}</span>
        </h3>
      </div>
    </div>

    <div class="mt-3">
      <div class="d-flex justify-content-center">
        {{ getdevisMonoFiche.data.devis.entreprise.lib }} -
        {{ getdevisMonoFiche.data.devis.entreprise.rue }} - au capital de
        {{ getdevisMonoFiche.data.devis.entreprise.cp }} Siret :{{
          getdevisMonoFiche.data.devis.entreprise.siret
        }}
        - N° {{ getdevisMonoFiche.data.devis.entreprise?.num_tva }}
      </div>
      <div class="d-flex justify-content-center">
        Courriel :
        {{ getdevisMonoFiche.data.devis.entreprise.email }}
      </div>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        Terminer
      </b-button>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters, mapActions, mapState } from "vuex";
import domain from "@/environment";
import axios from "axios";
import { domains } from "@/environment";
export default {
  data() {
    return {
      phrases: [
        "Rénovation d’ampleur d’une maison individuelle",
        "Isolation de combles ou de toiture",
        "Isolation des murs",
        "Isolation d’un plancher",
        "Ventilation mécanique simple flux hygroréglable",
        "Chauffe-eau thermodynamique à accumulation",
        "Pompe à chaleur de type air/eau",
        "Fenêtre ou porte-fenêtre complète avec vitrage isolant",
      ],
      downloadUrl: domains.download,
      galleryUrl: domains.gallery,
      readActivated: [
        {
          bool: false,
        },
      ],
      showGroup: [
        {
          bool: true,
        },
      ],
      moyens_paiement: [],
    };
  },
  props: {
   
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
    }),
    ...mapGetters(["getOnlineUser", "getdevisMonoFiche"]),
    onlineUser() {
      return this.getOnlineUser;
    },
    groupedProducts() {
      const products =
        this.getdevisMonoFiche.data?.GroupeLigneDocument_produit || [];
      return products.reduce((groups, product) => {
        const groupId = product.groupelignedocument_id;
        if (!groups[groupId]) {
          groups[groupId] = [];
        }
        groups[groupId].push(product);
        return groups;
      }, {});
    },
  },
  async created() {
    const devi_ID = this.getdevisMonoFiche.data?.devis?.id;
    try {
      const data = await this.getPaiement(devi_ID);
      this.moyens_paiement = data;
    } catch (error) {
      console.error("Échec de la récupération des moyens de paiement :", error);
    }
  },
  watch: {
    getdevisMonoFiche: {
      handler() {},
      deep: true,
    },
  },
  methods: {
    ...mapActions(["setDevisMonoFiche"]),
   
    async getPaiement(devis_ID) {
      try {
        const response = await axios.get(domain + `/devis_moyens_paiement`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
          params: {
            devis_id: devis_ID,
          },
        });

        return response.data.data;
      } catch (error) {
        console.error("Failed to fetch payment state:", error);
        throw new Error(
          error.response?.data?.message ||
            "An error occurred while fetching the payment information."
        );
      }
    },

    calculeRest() {
      return (
        this.getdevisMonoFiche.data.total_TTC -
        this.getdevisMonoFiche.data.devis.montant_cee
      );
    },

    formatDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");
    },

    valideDevis() {

   if (
    this.getdevisMonoFiche.data.devis.client &&
    this.getdevisMonoFiche.data.devis.logement &&
    (this.getdevisMonoFiche.data.devis.partenaire ||
      this.getdevisMonoFiche.data.devis.mandataire) &&
    this.getdevisMonoFiche.data.devis.debut_devis &&
    this.getdevisMonoFiche.data.devis.fin_devis &&
    this.getdevisMonoFiche.data.devis.cp_travaux &&
    this.getdevisMonoFiche.data.devis.visite_technique &&
    this.getdevisMonoFiche.data.devis.rue_travaux &&
    this.getdevisMonoFiche.data.devis.ville_travaux
  ) {
    const devisData = this.getdevisMonoFiche.data.devis;
    const groupeLigneDocuments =
      this.getdevisMonoFiche.data.devis.GroupeLigneDocument || [];

    let isValid = true;

    for (const ligne of groupeLigneDocuments) {
      const typeGroup = ligne.type_group;
      const typeValid =
        (typeGroup === "BAR-EN-101" && devisData.isolation_combles_toiture) ||
        (typeGroup === "BAR-EN-102" && devisData.isolation_murs) ||
        (typeGroup === "BAR-EN-103" && devisData.isolation_plancher) ||
        (typeGroup === "BAR-TH-171" && devisData.systeme_chauffage) ||
        (typeGroup === "BAR-TH-143" && devisData.systeme_solaire) ||
        (typeGroup === "BAR-TH-101" && devisData.chauffe_eau) ||
        (typeGroup === "BAR-TH-113" && devisData.chaudiere_biomasse) ||
        (typeGroup === "BAR-TH-112" && devisData.chauffage_au_bois) ||
        (typeGroup === "BAR-TH-127" &&
          devisData.systeme_ventilation_mecanique_controlee) ||
        (typeGroup === "BAR-TH-129" && devisData.pompe_chaleur_air_air) ||
        (typeGroup === "BAR-TH-148" &&
          devisData.chauffe_eau_thermodynamiques) ||
        (typeGroup === "BAR-TH-137" &&
          devisData.raccordement_batiment_residentiel_reseau_chaleur) ||
        (typeGroup === "BAR-EN-104" && devisData.fenetre_vitrage_complet) ||
        (typeGroup === "BAR-TH-175" && devisData.renovation_ampleur_individuelle) ||
        (typeGroup === "BAR-TH-174" && devisData.renovation_ampleur_individuelle) ||
        (typeGroup === "BAR-TH-173" && devisData.systeme_regulation_horaire);
      if (!typeValid) {
        isValid = false;
        break;
      }
    }

    
    if (isValid) {
      this.getdevisMonoFiche.data.devis.brouillon = 0;
      this.getdevisMonoFiche.data.brouillon = 0;
    
    } else {
      this.getdevisMonoFiche.data.devis.brouillon = 1;
      this.getdevisMonoFiche.data.brouillon = 1;
      this.setDevisMonoFiche(this.getdevisMonoFiche);
    }
   
  } else {
  
    this.getdevisMonoFiche.data.devis.brouillon = 1;
    this.getdevisMonoFiche.data.brouillon = 1;
    delete this.getdevisMonoFiche.data.devis.interlocuteur_id;
    this.setDevisMonoFiche(this.getdevisMonoFiche);
    this.$emit("changeStep", 9);
  }
},

    goToNextStep() {
    
      delete this.getdevisMonoFiche.data.devis.interlocuteur_id;
      this.setDevisMonoFiche(this.getdevisMonoFiche);
      this.$emit("changeStep", 9);
      this.$router.push("/devis/list");

      this.valideDevis();
    },

    goToPrevStep() {
    
      this.$emit("changeStep", 7);
    },
  },
};
</script>

<style lang="scss" scoped>
.wImag {
  width: 60%;
  height: 60%;
}
.descblock {
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
}

.truncated-desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.read-toggle {
  color: blue;
  cursor: pointer;
  margin-left: 5px;
}

.custom-dropdown .dropdown-menu {
  box-shadow: none !important;
  margin-right: 50px;
  left: auto;
  right: 0;
}
.dropdown-menu.show {
  display: block;
  margin-right: 120px;
}
.loding {
  height: 100% !important;
}
.brouillon {
  border-radius: 25px;
}
.bg-color {
  border-radius: 5px;

  background-color: white;
}
div {
  font-size: 13px;
}
.divborder {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  color: #28367a;
  width: 100%;
}
.divborder::after,
.divborder::before {
  content: "";
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  height: 2px;
  background-color: #000000;
}

.justify {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
b {
  color: #28367a;
}
.red {
  font-weight: bold;
  color: #e4261a;
}
.part {
  padding: 0;
  span {
    font-size: 14px;
    font-weight: 500;
    color: #515151;
    word-wrap: break-word;
  }
  .ml {
    margin-left: 500px;
  }
  .cadre {
    background-color: #f8f8f8;
    margin-top: 1%;
    margin-bottom: 1%;
    box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
    padding: 13px;
    height: 350px;
  }
  .left {
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
    width: 30%;
  }

  .right {
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
    width: 23%;
    p {
      float: right;
      padding: 8px;
      background-color: #d0cccc;
      color: #000;
      width: 50%;
      font-weight: 600;
      text-align: center;
      margin: 0 0 4px;
    }
  }

  .part-title {
    color: #28367a;
    font-weight: 700;
    margin-right: 10px;
  }

  ul {
    list-style: none;

    li {
      @media only screen and (max-width: 1000px) {
        justify-content: space-between;
      }
      display: flex;
      align-items: center;
      color: #515151;

      span {
        display: block;
        width: 174px;
        font-size: 14px;
        white-space: nowrap;
      }

      .title {
        @media only screen and (max-width: 900px) {
          font-size: 12px;
        }
        color: #393939;
        font-weight: 600;
        font-size: 14px;
        margin-right: 10px;
      }
    }

    .traveauxtitle {
      @media only screen and (max-width: 900px) {
        padding: 0;
      }
      padding: 0 15px;
    }
  }
  .half-width {
    flex: 0 0 25%;
  }
  .flexEnd {
    display: flex;
    justify-content: space-between;
    .sousTraitant {
      p {
        color: #2167d9;
      }
    }
    .subtable {
      .complet {
        @media only screen and (max-width: 1000px) {
          margin-right: 10%;
          width: 100%;
        }
        border-collapse: separate;
        margin-right: 10%;
        width: 100%;
        height: 32px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        tr {
          height: 24px;
        }

        .thead {
          @media only screen and (max-width: 1000px) {
            padding: 6px !important;
          }
          background-color: #6472b3;
          color: #fff;
          max-width: 118px;
          height: 32px;
        }
        td {
          color: #000;
          background-color: #e9eaf2;
          width: 118px;
        }
      }
    }
  }
  .tabledonne {
    overflow-x: auto;
    margin-bottom: 4px;
  }
  table {
    padding-left: 6px;
    padding-right: 4px;
    margin: 14px 0;
    border-collapse: separate;
    th {
      font-weight: 400;
      font-size: 12px;
      background-color: #6472b3;
      padding: 4px;
      color: #fff;
      border: 1px solid #6472b3;
      text-align: center;
    }
    td {
      text-align: center;
      padding: 4px;
      background-color: #e9eaf2;
      .libblock {
        font-weight: 800;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    tr {
      height: 24px;
    }
  }
}
.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
}
.groupEdit {
  border: 1px solid #707070;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 16px;
  box-shadow: 0 0 2px 0 #707070;

  .entete {
    font-weight: 400;
    font-size: 12px;
    background-color: #28367a;
    padding: 4px;
    color: #fff;
    text-align: center;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
  }
  .action {
    display: flex;
    align-content: center;
    .trash,
    .ajout {
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 6px;
      color: #fff;
      height: 30px;
      width: 40%;
      margin: auto auto 15px auto;
    }
    .trash {
      background-color: #e4261a;
    }
  }
}

.textstyle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.inner-container .page-header button {
  margin-bottom: 0 !important;
  color: #fff;
  width: 0% !important;
  height: 43px;
}
.dropdown-btn {
  background-color: #28367a;
  text-align: center;
  padding: 10px 80px;
  margin-left: 20px;
  margin-right: 12px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.title {
  font-size: 18px;
  font-weight: 800;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 4px 0px #15223214;
}
.bg-color-gray {
  background-color: #e9eaf25b;
}
.dropdown-content {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 100%; /* Juste en dessous du bouton */
  left: 50%; /* Centre horizontalement par rapport au bouton */
  transform: translateX(-50%); /* Centre exactement le menu */
  background-color: #f9f9f9;
  min-width: 200px; /* Largeur minimale du menu */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content a {
  text-align: center;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.sort-arrow {
  cursor: pointer;
  color: grey;
  padding: 0 5px;
  font-size: 14px;
}

.sort-arrow:hover {
  color: white;
}

.active-sort {
  color: white;
  font-weight: bold;
}

.piece {
  background-color: #28367a;
  color: #fff;
  padding: 0px 15px;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:first-child {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  p {
    margin: 0;
    font-size: 14px;
    margin-right: 8px;
    overflow: hidden;
  }
  .icone-down-up {
    display: flex;
    align-items: center;
    p,
    span {
      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }

    button {
      background-color: transparent !important;
      border: transparent;
      box-shadow: none;
    }

    span {
      font-size: 14px;
      background-color: #6472b3;
      border-radius: 4px;
      padding: 2px 12px;
    }
  }
}

.group_financement {
  width: 100%;
  padding: 8px 12px;
  background-color: #eef1fd;
  border-radius: 5px;
}
.svg-inline--fa {
  margin: inherit;
}
.grey {
  background-color: #f5f6f8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  margin: 0 auto;
}

form .form-group {
  max-width: none !important;
}

.desclass {
  margin-top: 9px;
}
.cours {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  p {
    margin: 0;
    color: #fff;
    background-color: #d0cccc;
    width: 81px;
    height: 24px;
    font-size: 13px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    font-size: 14px;
  }
}
.content {
  .content-header {
    .input-group {
      @media only screen and (max-width: 1000px) {
        width: 59%;
      }
      width: 80%;
      position: relative;
      margin-bottom: 0;
      .icon-search {
        @media only screen and (max-width: 1000px) {
          display: none;
        }
        overflow: visible;
        position: absolute;
        left: 15px;
        top: 10px;
        color: #515151;
      }

      .icon-balance {
        cursor: pointer;
        overflow: visible;
        position: absolute;
        right: 15px;
        top: 10px;
        color: #28367a;
        outline: 0;

        .balance {
          color: #28367a;
        }
      }
      .form-control::placeholder {
        color: #515151;
        font-size: 12px;
      }
    }

    .filtre {
      @media only screen and (max-width: 1000px) {
        width: 39%;
      }
      width: 19%;
      background-color: #28367a;
      color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 4px;
      border-radius: 5px;
      font-size: 12px;
    }
  }

  & p {
    font-size: 12px;
    font-weight: 500;
    margin: 8px 15px 0 0;
  }
}

.body {
  padding: 15px;
  box-shadow: 1px 1px 24px #00000019;
  max-height: 60vh;
  overflow: auto;

  ul {
    list-style: none;
    & li {
      padding: 8px 0;
      cursor: pointer;
      background-color: #f8f8f8;
      margin-bottom: 4px;
      color: #000;
      border: none;
      box-shadow: none;
      transition: all 0.2s ease;
      text-align: center;

      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}
.justify {
  padding: 13px;
  .left {
    h2 {
      color: #28367a;
      font-weight: 700;
      margin-bottom: 0px;
    }
    .entrepriseinfo {
      color: #000;
      font-weight: 600;
    }
    .title {
      color: #28367a;
      font-weight: 700;
    }
    .adressinfo {
      display: grid;
    }
    .info {
      color: #515151;
    }
    .block {
      margin-top: 10px;
    }
  }
  .right {
    .span {
      color: #515151;
    }
    h2 {
      color: #28367a;
      font-weight: 700;
    }
    .adressinfo {
      display: grid;
    }
    .info {
      color: #515151;
    }
    .entrepriseinfo {
      color: #000;
      font-weight: 600;
    }
  }
}

.divborder {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  color: #28367a;
  width: 100%;
  p {
    margin: 0px 10px;
  }
}
.divborder::after,
.divborder::before {
  content: "";
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  height: 1px;
  background-color: #d8d8d8;
}
.part .table td.blocklibdesc {
  text-align: left;

  .descblock {
    white-space: normal;
    max-width: 400px;
    height: 72px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.575;
    position: relative;
  }
  .read {
    bottom: -2px;
    right: 0;
    display: inline-block;
    font-size: 12px;
    font-weight: 800;
    color: #28367a;
  }
}

.detailClient {
  background-color: #fff;
  box-shadow: 1px 1px 24px #00000019;
  .clientTop {
    padding: 16px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #707070;

    & .prodimg {
      max-width: 185px;
      max-height: 231px;
    }

    .star {
      color: #ffc400;
      position: absolute;
      top: 12px;
      right: 8px;
      font-size: 30px;
    }
  }

  .clientMid {
    padding: 16px 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #707070;
    & > div {
      width: 50%;
    }
  }

  .clientBot {
    padding: 16px 8px;
  }

  & h4 {
    color: #28367a;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  & p {
    color: #515151;
    font-size: 16px;
    font-weight: 500;
  }
}
.filtre {
  display: flex;
  align-items: center;
  gap: 6px;

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;
    margin: 2px 0 !important;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}

.justify {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.part {
  /* padding:0; */
  span {
    font-size: 14px;
    font-weight: 500;
    color: #515151;
    word-wrap: break-word;
  }
  .remarqueblock {
    margin-bottom: 5px;
  }
  .cadre {
    background-color: #f8f8f8;
    margin-top: 1%;
    margin-bottom: 1%;
    box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
    padding: 13px;
  }
  .left {
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
    width: 30%;
  }

  .right {
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
    width: 23%;
    p {
      float: right;
      padding: 8px;
      background-color: #d0cccc;
      color: #000;
      width: 50%;
      font-weight: 600;
      text-align: center;
      margin: 0 0 4px;
    }
  }

  .fact {
    font-size: 16px;
    color: #28367a;
  }

  h2,
  h3 {
    font-weight: 600;
  }

  h2 {
    font-size: 16px;
  }
  h3 {
    color: #515151;
    font-size: 16px;
    margin: 0;
  }

  ul {
    list-style: none;

    li {
      @media only screen and (max-width: 1000px) {
        justify-content: space-between;
      }
      display: flex;
      align-items: center;
      color: #515151;

      span {
        display: block;
        width: 174px;
        font-size: 14px;
        white-space: nowrap;
      }

      .title {
        @media only screen and (max-width: 900px) {
          font-size: 12px;
        }
        color: #393939;
        font-weight: 600;
        font-size: 14px;
        margin-right: 10px;
      }
    }

    .traveauxtitle {
      @media only screen and (max-width: 900px) {
        padding: 0;
      }
      padding: 0 15px;
    }
  }
  .flexEnd {
    display: flex;
    justify-content: space-between;
    .sousTraitant {
      p {
        color: #2167d9;
      }
    }
    .subtable {
      .complet {
        @media only screen and (max-width: 1000px) {
          margin-right: 10%;
          width: 100%;
        }
        border-collapse: separate;
        margin-right: 10%;
        width: 100%;
        height: 32px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        tr {
          height: 24px;
        }

        .thead {
          @media only screen and (max-width: 1000px) {
            padding: 6px !important;
          }
          background-color: #6472b3;
          color: #fff;
          max-width: 118px;
          height: 32px;
        }
        td {
          color: #000;
          background-color: #e9eaf2;
          width: 118px;
        }
      }
    }
  }
  .tabledonne {
    overflow-x: auto;
    margin-bottom: 4px;
  }
  table {
    border-collapse: separate;
    th {
      font-weight: 400;
      font-size: 12px;
      background-color: #6472b3;
      padding: 4px;
      color: #fff;
      border: 1px solid #6472b3;
      text-align: center;
    }
    td {
      text-align: center;
      padding: 4px;
      background-color: #e9eaf2;
      .libblock {
        font-weight: 800;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    tr {
      height: 24px;
    }
  }
}
.group {
  @media only screen and (max-width: 1000px) {
    overflow: hidden;
    padding: 8px;
  }
  box-shadow: 0px 2px 6px #00000019;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  margin-bottom: 15px;
  .part {
    overflow: auto;
    padding: 15px;
    margin-bottom: 0;
  }
}

.flexEndTotale {
  display: flex;
  justify-content: flex-end;
  .subtableTotale {
    .completTotale {
      @media only screen and (max-width: 1000px) {
        margin-right: 10%;
        width: 100%;
      }
      border-collapse: separate;
      margin-right: 10%;
      width: 100%;
      height: 32px;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      tr {
        height: 24px;
      }

      .theadTotale {
        @media only screen and (max-width: 1000px) {
          padding: 6px !important;
        }
        background-color: #28367a;
        color: #fff;
        max-width: 118px;
        height: 32px;
      }
      td {
        color: #000;
        background-color: #e9eaf2;
        width: 135px;
      }
    }
  }
}
.textstyle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

div {
  font-size: 13px;
}
.divborder {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  color: #28367a;
  width: 100%;
  height: 3px;
}

.divborder::after,
.divborder::before {
  content: "";
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  height: 2px;
  background-color: #000000;
}

.justify {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.brouillon {
  border-radius: 25px;
}
b {
  color: #28367a;
}
.red {
  font-weight: bold;
  color: #e4261a;
}
.part {
  /* padding: 8px 0; */
  span {
    font-size: 14px;
    font-weight: 500;
    color: #515151;
    word-wrap: break-word;
  }

  .cadre {
    background-color: #f8f8f8;
    margin-top: 1%;
    margin-bottom: 1%;
    box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
    padding: 13px;
    height: 350px;
  }
  .left {
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
    width: 30%;
  }

  .right {
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
    width: 23%;
    p {
      float: right;
      padding: 8px;
      background-color: #d0cccc;
      color: #000;
      width: 50%;
      font-weight: 600;
      text-align: center;
      margin: 0 0 4px;
    }
  }

  ul {
    list-style: none;

    li {
      @media only screen and (max-width: 1000px) {
        justify-content: space-between;
      }
      display: flex;
      align-items: center;
      color: #515151;

      span {
        display: block;
        width: 174px;
        font-size: 14px;
        white-space: nowrap;
      }

      .title {
        @media only screen and (max-width: 900px) {
          font-size: 12px;
        }
        color: #393939;
        font-weight: 600;
        font-size: 14px;
        margin-right: 10px;
      }
    }

    .traveauxtitle {
      @media only screen and (max-width: 900px) {
        padding: 0;
      }
      padding: 0 15px;
    }
  }
  .half-width {
    flex: 0 0 25%;
  }
  .flexEnd {
    display: flex;
    justify-content: space-between;
    .sousTraitant {
      p {
        color: #2167d9;
      }
    }
    .subtable {
      .complet {
        @media only screen and (max-width: 1000px) {
          margin-right: 10%;
          width: 100%;
        }
        border-collapse: separate;
        margin-right: 10%;
        width: 100%;
        height: 32px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        tr {
          height: 24px;
        }

        .thead {
          @media only screen and (max-width: 1000px) {
            padding: 6px !important;
          }
          background-color: #6472b3;
          color: #fff;
          max-width: 118px;
          height: 32px;
        }
        td {
          color: #000;
          background-color: #e9eaf2;
          width: 118px;
        }
      }
    }
  }
  .tabledonne {
    overflow-x: auto;
    margin-bottom: 4px;
  }
  table {
    border-collapse: separate;
    th {
      font-weight: 400;
      font-size: 12px;
      background-color: #6472b3;
      padding: 4px;
      color: #fff;
      border: 1px solid #6472b3;
      text-align: center;
    }
    td {
      text-align: center;
      padding: 4px;
      background-color: #e9eaf2;
      .libblock {
        font-weight: 800;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    tr {
      height: 24px;
    }
  }
}
.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
}
.groupEdit {
  border: 1px solid #707070;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 16px;
  box-shadow: 0 0 2px 0 #707070;

  .entete {
    font-weight: 400;
    font-size: 12px;
    background-color: #28367a;
    padding: 4px;
    color: #fff;
    text-align: center;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
  }
  .action {
    display: flex;
    align-content: center;
    .trash,
    .ajout {
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 6px;
      color: #fff;
      height: 30px;
      width: 40%;
      margin: auto auto 15px auto;
    }
    .trash {
      background-color: #e4261a;
    }
  }
}

.textstyle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
